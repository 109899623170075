import React from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Badge,
  Typography,
  Box,
} from "@mui/material";
import { AvatarIntern } from "src/components/avatar";
import { useSelector } from "react-redux";
import { selectCurrentUserData } from "../../../../features/auth/authSlice";

function ChatList({ chats, handleOpenChat }) {
  const currentUserData = useSelector(selectCurrentUserData);

  const getUnreadCount = (chat) => {
    return chat.messages.filter(
      (msg) => !msg.read && msg.from !== currentUserData.id
    ).length;
  };

  return (
    <List disablePadding>
      {chats.map((chat) => {
        const unreadCount = getUnreadCount(chat);
        const lastMessageText =
          chat.messages[chat.messages.length - 1]?.text || "No messages yet";

        // Extract the first and last name from the other user in the chat
        const otherUser = chat.users.find(
          (user) => user._id !== currentUserData.id
        );
        const otherUserName = `${otherUser?.firstName || ""} ${
          otherUser?.lastName || ""
        }`;

        return (
          <ListItem
            key={chat._id}
            button
            onClick={() => handleOpenChat(chat)}
            sx={{ alignItems: "flex-start" }}
          >
            <ListItemAvatar>
              <AvatarIntern name={otherUserName} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" fontWeight="bold">
                    {otherUserName}
                  </Typography>
                  {unreadCount > 0 && (
                    <Badge badgeContent={unreadCount} color="error" />
                  )}
                </Box>
              }
              secondary={lastMessageText}
              primaryTypographyProps={{ fontWeight: "bold" }}
              secondaryTypographyProps={{
                color: "textSecondary",
                noWrap: true,
              }}
            />
          </ListItem>
        );
      })}
    </List>
  );
}

export default ChatList;
