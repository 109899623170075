import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Logo from "../components/logo/Logo";
import { Box, Button, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import PublicSurveyForm from "../components/forms/Forms/PublicSurveyForm";
import {
  answerPublicSurvey,
  getPublicSurveyQuestions,
} from "../app/api/axios/services/public.service";
import Loading from "../components/spinners/Loading";

function PublicSurveyPage(props) {
  const hasFetched = React.useRef(false);

  // State to store survey data
  const [survey, setSurvey] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // Get the userId param from the URL.
  let { surveyId } = useParams();

  const handleAnswerSurvey = async () => {
    let testAnswer = {
      answer1: 5,
      answer2: 5,
      answer3: 2,
      answer4: 7,
      answer5: 3,
    };

    try {
      let result = await answerPublicSurvey(surveyId, testAnswer);
      console.log("result :>> ", result);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  // Fetch survey questions
  useEffect(() => {
    if (hasFetched.current) return;
    const fetchSurvey = async () => {
      try {
        setLoading(true); // Start loading
        const response = await getPublicSurveyQuestions(surveyId);
        setSurvey(response.data.survey); // Store the survey data in state
      } catch (err) {
        setError("Failed to load survey");
        console.error("Error fetching survey:", err);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    // Only fetch data if dialog.data.fileData._id exists
    if (surveyId) {
      fetchSurvey(); // Call the fetch function when the component mounts
    }
    hasFetched.current = true; // Set the flag to true after the effect runs
  }, [surveyId]);

  console.log("survey :>> ", survey);

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );
  if (error) return <div>{error}</div>;

  if (survey) {
    return (
      <Box>
        <div style={{ margin: 5 }}>
          <Logo />
        </div>
        <Typography textAlign={"center"} variant="h1">
          Befragung
        </Typography>
        <Typography
          sx={{ fontSize: { xs: "125%" }, py: 2 }}
          textAlign={"center"}
          variant="h2"
        >
          {survey.surveyTitle}
        </Typography>

        <Typography sx={{ p: 1 }} variant="body1" align="center" gutterBottom>
          {survey.surveyDescription}
        </Typography>
        <div>
          <PublicSurveyForm survey={survey} />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAnswerSurvey}
          >
            ABSENDEN
          </Button>
        </div>
      </Box>
    );
  }
}

PublicSurveyPage.propTypes = {};

export default PublicSurveyPage;
