import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import {
  styled,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import Iconify from "src/components/iconify";
import PasswordTextField from "src/components/forms/PasswordTextField"; // Import the PasswordTextField component
import { useChangeUsersPWMutation } from "src/features/users/usersApiSlice";

// Styled Dialog
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// PropTypes
PasswordChangeDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

// Main Component
export default function PasswordChangeDialog({ handleClose, user }) {
  const [changeUsersPW] = useChangeUsersPWMutation();
  const {
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const password = watch("password", "");

  const onSubmit = async (data) => {
    try {
      console.log("New Password: ", data.password);
      await changeUsersPW({ userId: user?._id, newPassword: data.password });
      handleClose();
      reset();
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
        }}
      >
        <Typography
          sx={{ textAlign: "center", fontSize: "30px", flex: 1 }}
          id="customized-dialog-title"
        >
          Passwort ändern
        </Typography>
        <Box sx={{ flex: "0 0 auto" }}>
          <Iconify
            onClick={handleClose}
            icon="mingcute:close-line"
            style={{ fontSize: "50px", color: "action.active" }}
            sx={{
              transition: "transform 0.1s", // Smooth transition for transform
              "&:hover": {
                transform: "scale(1.8)", // Scale up the icon when hovered
              },
            }}
          />
        </Box>
      </Box>
      <DialogContent dividers>
        <Typography variant="body1" color="textPrimary">
          Ändern Sie das Passwort für den Benutzer: {user.firstName}{" "}
          {user.lastName}
        </Typography>
        <br />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ my: 2 }}>
            <PasswordTextField
              name="password"
              control={control}
              label="Neues Passwort"
              fullWidth
              required
              rules={{ required: "Bitte Passwort eingeben." }}
              error={!!errors.password}
              helperText={errors.password ? errors.password.message : ""}
            />
          </Box>
          <PasswordTextField
            name="confirmPassword"
            control={control}
            label="Passwort bestätigen"
            fullWidth
            required
            rules={{
              required: "Bitte Passwort bestätigen.",
              validate: (value) =>
                value === password || "Passwörter stimmen nicht überein",
            }}
            error={!!errors.confirmPassword}
            helperText={
              errors.confirmPassword ? errors.confirmPassword.message : ""
            }
          />
          <DialogActions>
            <Button
              style={{ background: "#9E9E9E" }}
              variant="contained"
              onClick={handleClose}
            >
              Abbrechen
            </Button>
            <Button type="submit" color="secondary" variant="contained">
              Ändern
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </BootstrapDialog>
  );
}
