import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { headCells, statuses } from "./fileDataTableUtils";
import FileDataTableItemMenu from "./FileDataTableItemMenu";
import { useSelector } from "react-redux";
import { selectCurrentUserData } from "../../../../features/auth/authSlice";

function FileDataTableRow({
  file,
  handleEditFileData,
  handleClick,
  isItemSelected,
  labelId,
}) {
  const [open, setOpen] = React.useState(false);
  const userData = useSelector(selectCurrentUserData);

  return (
    <>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" }, cursor: "pointer" }}
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        // onClick={(event) => handleClick(event, file._id)}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            onClick={(event) => handleClick(event, file._id)}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <Icon icon="mdi:keyboard-arrow-up" />
            ) : (
              <Icon icon="mdi:keyboard-arrow-down" />
            )}
          </IconButton>
        </TableCell>
        {headCells.map((headCell) => {
          const { value, align } = headCell;
          if (headCell?.first) {
            return (
              <TableCell component="th" scope="row">
                {file.fileName}
              </TableCell>
            );
          }
          if (value === "status") {
            return <TableCell align={align}>{statuses[file.status]}</TableCell>;
          }
          if (headCell?.type === "boolean") {
            return (
              <TableCell align={align}>{file[value] ? "Ja" : "Nein"}</TableCell>
            );
          }
          if (headCell?.value === "recallDate") {
            return (
              <TableCell align={align}>
                {file[value]
                  ? new Date(file[value]).toLocaleDateString()
                  : "Nein"}
              </TableCell>
            );
          }
          if (headCell?.type === "user") {
            return (
              <TableCell align={align}>
                {file[value] && file[value].fullName}
              </TableCell>
            );
          }
          if (headCell?.type === "date") {
            return (
              <TableCell align={align}>
                {new Date(file[value]).toLocaleString()}
              </TableCell>
            );
          }
          return <TableCell align={align}>{file[value]}</TableCell>;
        })}
        <TableCell
          align="center"
          style={{ display: "flex", gap: "4px", alignItems: "center" }}
        >
          {file?.status === "revision" && (
            <Button
              onClick={() => handleEditFileData(file, "REVIEW")}
              variant="contained"
              color="error"
            >
              PRÜFEN
            </Button>
          )}
          {file?.status === "released" &&
            file?.readingConfirmation &&
            file?.readingConfirmationList?.includes(userData.id) && (
              <Button
                onClick={() => handleEditFileData(file, "CONFIRMATION")}
                variant="contained"
                color="secondary"
              >
                LESEN
              </Button>
            )}
          <FileDataTableItemMenu
            fileData={file}
            handleEditFileData={handleEditFileData}
            userId={userData.id}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Historie
              </Typography>
              <Table size="small" aria-label="file history">
                <TableHead>
                  <TableRow>
                    <TableCell>Zuletzt geändert</TableCell>
                    <TableCell>Benutzer</TableCell>
                    <TableCell>Beschreibung</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {file?.workflow?.map((h) => (
                    <TableRow>
                      <TableCell>
                        {new Date(h.updatedAt).toLocaleString()}
                      </TableCell>
                      <TableCell>{h.updatedBy}</TableCell>
                      <TableCell>{h.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

FileDataTableRow.propTypes = {
  file: PropTypes.object,
  handleEditFileData: PropTypes.func,
  handleClick: PropTypes.func,
  isItemSelected: PropTypes.bool,
  labelId: PropTypes.string,
};

export default FileDataTableRow;
