import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  styled,
} from "@mui/material";
import { Slide } from "@mui/material";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useFirstLoginMutation } from "../../../features/users/usersApiSlice";
import useOffices from "../../../hooks/useOffices";
import { useDispatch } from "react-redux";
import { setDialog } from "../../../features/dialog/dialogSlice";
import navConfig from "../../../components/nav-section/navConfig";

const Transition = React.forwardRef((props, ref) => (
  <Slide
    // style={{ transitionDuration: "5000ms" }}
    direction="up"
    ref={ref}
    {...props}
  />
));

// Styled component for the welcome message
const WelcomeMessage = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: theme.typography.h4.fontSize,
  textAlign: "center",
  marginBottom: theme.spacing(2),
}));

// Component for the welcome dialog
const WelcomeDialog = ({ userData, handleClose }) => {
  const dispatch = useDispatch();

  const [showAnimation, setShowAnimation] = useState(false);
  const [firstLogin] = useFirstLoginMutation();
  const { hasOffices } = useOffices();

  // Use useEffect to trigger animation after dialog mounts
  useEffect(() => {
    setShowAnimation(true);
  }, []);

  const handleDontShowAgain = async () => {
    try {
      await firstLogin();
      handleClose();
    } catch (error) {
      console.log("error :>> ", error);
      window.alert(
        "Fehler bei der Kommunikation mit dem Server bitte versuchen Sie es erneut."
      );
    }
  };

  const handleOpenCreateOfficeDialog = () => {
    dispatch(
      setDialog({
        title: "Neue Praxis erstellen",
        type: "CREATE",
        content: "office-form",
        firstLogin: true,
      })
    );
  };

  console.log("userData :>> ", userData);
  console.log("hasOffices :>> ", hasOffices);

  return (
    <Dialog
      TransitionComponent={Transition}
      open={true}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
    >
      <DialogActions
        style={{
          borderBottomStyle: "solid",
          borderWidth: "2px",
          borderColor: "lightgrey",
          paddingTop: 0,
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={handleClose} color="inherit">
          <Icon width="40" icon="icon-park-solid:close-one" />
        </Button>
      </DialogActions>
      <DialogContent>
        {/* Apply Slide animation */}
        <Slide direction="down" in={showAnimation} timeout={600}>
          <div>
            <WelcomeMessage variant="h2">
              Willkommen im Mojave-Med QM System!
            </WelcomeMessage>
          </div>
        </Slide>
        <div style={{ marginTop: 25 }}>
          <Typography variant="h5">
            Schön, dass Sie sich für unsere Software entschieden haben. Gerne
            unterstützen wir Sie in den ersten Schritten mit diesem Tool.
            Sollten Sie die Einleitung nicht benötigen, können Sie jederzeit
            unten rechts auf
            <Button sx={{ mx: 2 }} variant="contained" color="error">
              NICHT WIEDER ANZEIGEN
            </Button>
            klicken.
          </Typography>
          {!hasOffices && (
            <div>
              <Typography sx={{ mt: 3 }} variant="h5">
                Daten und Dokumente werden grundsätzlich einer oder mehreren
                Praxen zugewiesen. Es scheint, dass für Ihre Anwendung noch
                keine Praxis erstellt wurde. Bitte erstellen Sie zunächst eine
                Praxis.
              </Typography>
              <Button
                onClick={handleOpenCreateOfficeDialog}
                variant="contained"
                color="primary"
              >
                PRAXIS ERSTELLEN
              </Button>
            </div>
          )}
          <Typography sx={{ mt: 3 }} variant="h5">
            Daten und Dokumente werden grundsätzlich, je nach Auswahl, einer
            oder mehreren Praxen zugewiesen. Links im Menü unter Ihrem Profil
            sehen Sie die aktuell ausgewählte Praxis. Für die angegebene
            Hauptpraxis wurde automatisch eine Praxis erstellt und Ihnen
            zugewiesen. Sollte Ihr Zugang die Funktion enthalten, mehrere Praxen
            zu verwalten, können Sie dort zwischen den Praxen wechseln.
          </Typography>
          <Typography sx={{ mt: 3 }} variant="h5">
            Darunter sehen Sie das Menü mit folgenden Funktionen:
          </Typography>
          <List>
            {navConfig.map((navEl, i) => (
              <ListItem key={"navEl-" + i}>
                <ListItemIcon>{navEl.icon}</ListItemIcon>
                <ListItemText primary={navEl.title} secondary={navEl.info} />
              </ListItem>
            ))}
          </List>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="primary">
          SCHLIESSEN
        </Button>
        <Button onClick={handleDontShowAgain} variant="contained" color="error">
          NICHT WIEDER ANZEIGEN
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WelcomeDialog;
