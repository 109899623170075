import React from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { AvatarIntern } from "src/components/avatar";

function UserList({ allUsers, handleStartNewChat }) {
  return (
    <List
      disablePadding
      subheader={
        <ListSubheader
          disableSticky
          sx={{ py: 1, px: 2.5, typography: "overline" }}
        >
          Verfügbare Benutzer
        </ListSubheader>
      }
    >
      {allUsers.map((user) => (
        <ListItem
          key={user._id}
          button
          onClick={() => handleStartNewChat(user)}
          sx={{ alignItems: "flex-start" }}
        >
          <ListItemAvatar>
            <AvatarIntern name={`${user?.firstName} ${user?.lastName}`} />
          </ListItemAvatar>
          <ListItemText
            primary={user?.firstName + " " + user?.lastName}
            primaryTypographyProps={{ fontWeight: "bold" }}
          />
        </ListItem>
      ))}
    </List>
  );
}

export default UserList;
