import React from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  Typography,
  Box,
  Button,
  ListItem,
  List,
  Divider,
} from "@mui/material";
import PasswordTextField from "src/components/forms/PasswordTextField";

import { useSelector } from "react-redux";
import { selectCurrentUserData } from "src/features/auth/authSlice";
import { useUpdatePasswordMutation } from "src/features/users/usersApiSlice";

const NewPasswordSettings = () => {
  const currentUserData = useSelector(selectCurrentUserData);
  const [updatePassword] = useUpdatePasswordMutation();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const onSubmit = async (data) => {
    try {
      const result = await updatePassword({
        username: currentUserData?.id,
        oldPassword: data?.oldPassword,
        newPassword: data?.newPassword,
      });
      console.log("Password updated successfully:");
      reset();
    } catch (error) {
      console.error("Error updating password:", error);
    }
  };

  const validatePasswordCriteria = (value) => {
    const passwordCriteria = [
      { test: /.{8,}/, message: "Mindestens 8 Zeichen lang" },
      { test: /[A-Z]/, message: "Mindestens ein Großbuchstabe (A-Z)" },
      { test: /[a-z]/, message: "Mindestens ein Kleinbuchstabe (a-z)" },
      { test: /\d/, message: "Mindestens eine Zahl (0-9)" },
      {
        test: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
        message: "Mindestens ein Sonderzeichen (!@#$%^&*()_+[]{}|;:'\".,?)",
      },
    ];

    for (const criterion of passwordCriteria) {
      if (!criterion.test.test(value)) {
        return "Kriterien nicht erfüllt";
      }
    }
    return true;
  };

  const validateConfirmPassword = (value) => {
    return value === watch("newPassword") || "Passwörter stimmen nicht überein";
  };

  return (
    <>
      <Divider sx={{ mb: 3 }} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h6" color="textSecondary" fontWeight="bold">
          Ihr Passwort muss folgende Kriterien erfüllen:
        </Typography>

        <Typography variant="body1" color="textSecondary">
          <List sx={{ pl: 1 }}>
            <ListItem>- Mindestens 8 Zeichen lang</ListItem>
            <ListItem>- Mindestens ein Großbuchstabe (A-Z)</ListItem>
            <ListItem>- Mindestens ein Kleinbuchstabe (a-z)</ListItem>
            <ListItem>- Mindestens eine Zahl (0-9)</ListItem>
            <ListItem>
              - Mindestens ein Sonderzeichen (!@#$%^&*()_+[]{}|;:'".,?)
            </ListItem>
          </List>
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <PasswordTextField
              name="oldPassword"
              control={control}
              label="Altes Passwort"
              fullWidth
              required
              rules={{ required: "Bitte Passwort eingeben." }}
              error={!!errors.oldPassword}
              helperText={errors.oldPassword ? errors.oldPassword.message : ""}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <PasswordTextField
              name="newPassword"
              control={control}
              label="Neues Passwort"
              fullWidth
              required
              rules={{ validate: validatePasswordCriteria }}
              error={!!errors.newPassword}
              helperText={errors.newPassword ? errors.newPassword.message : ""}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <PasswordTextField
              name="confirmPassword"
              control={control}
              label="Neues Passwort wiederholen"
              fullWidth
              required
              rules={{ validate: validateConfirmPassword }}
              error={!!errors.confirmPassword}
              helperText={
                errors.confirmPassword ? errors.confirmPassword.message : ""
              }
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            sx={{ mt: 2 }}
          >
            Passwort ändern
          </Button>
        </Box>
      </form>
    </>
  );
};

export default NewPasswordSettings;
