import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import { Typography } from "@mui/material";
import ReleaseDocumentForm from "../../forms/Forms/ReleaseDocumentForm";

export default function ReleaseFileAdminDialog({
  fileData,
  handleSave,
  loading,
  action,
}) {
  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      recallAmount: "1",
      recallPeriod: "years",
    },
  });
  const revisionChecked = watch("revisionDoctor");
  const checkCreateProof = watch("checkCreateProof");

  const status = fileData.status;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (formData) => {
    // CHECK IF FILE IS TO BE REVIEWED!
    handleSave(formData, "RELEASE");
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="error"
        sx={{ mr: 2 }}
        disabled={loading}
        onClick={handleClickOpen}
      >
        {loading ? "LADEN..." : "FREIGEBEN"}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`${fileData.fileName} freigeben`}
        </DialogTitle>
        <DialogContent>
          <Typography>
            Wenn Sie das Dokument freigeben, ist es für alle Benutzer verfügbar.
          </Typography>
          <br />
          <Typography>
            Mittels freigegebenen Formblättern können Nachweise erzeugt werden.
          </Typography>
          <br />
          <div>
            <form className="form">
              <ReleaseDocumentForm
                control={control}
                revisionChecked={revisionChecked}
                checkCreateProof={checkCreateProof}
              />
            </form>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="secondary"
            autoFocus
          >
            {revisionChecked ? "AN ARZT SENDEN" : "FREIGEBEN"}
          </Button>
          <Button onClick={handleClose}>Abbrechen</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
