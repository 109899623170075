import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DragDropContext } from "@hello-pangea/dnd";
// @mui
import {
  Stack,
  Typography,
  Button,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  Chip,
  Menu,
  MenuItem,
  IconButton,
  Box,
  ListItemIcon,
} from "@mui/material";
import { styled, alpha, useTheme } from "@mui/material/styles";
// components
import Column from "../sections/@dashboard/tasks/Column";
import {
  MobileAccordion,
  TaskFilterSidebar,
  TaskSort,
} from "../sections/@dashboard/tasks";
// mock
import Iconify from "../components/iconify";
import { setDialog } from "../features/dialog/dialogSlice";
import {
  useGetTasksQuery,
  useUpdateTaskStatusMutation,
} from "../features/task/taskApiSlice";
import {
  useDeleteTaskFilterMutation,
  useGetUsersTaskfilterQuery,
  useReplacetaskfilterMutation,
} from "../features/users/usersApiSlice";
import { apiSlice } from "../app/api/apiSlice";

// ----------------------------------------------------------------------

const StyledSearch = styled(OutlinedInput)(({ theme, width }) => ({
  width: width,
  padding: "0px 12px",
  height: 50,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `2px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const columns = [
  { id: "1", title: "Neu", value: "new", color: "#e1ecf0" },
  {
    id: "2",
    title: "In Bearbeitung",
    value: "edit",
    color: "#e8e8e8",
  },
  {
    id: "3",
    title: "In Überprüfung",
    value: "revision",
    color: "#b2ebf2",
  },
  { id: "4", title: "Erledigt", value: "done", color: "#c9f1c9" },
];
// ----------------------------------------------------------------------

export default function TaskPage() {
  const { data: tasks, isLoading: tasksLoading } = useGetTasksQuery();
  const { data: taskFilters, isLoading: filtersLoading } =
    useGetUsersTaskfilterQuery();

  const [updateTaskStatus] = useUpdateTaskStatusMutation();
  // delete taskfilter
  const [deleteTaskFilter] = useDeleteTaskFilterMutation();
  // update task filter (new mutation)
  const [replacetaskfilter] = useReplacetaskfilterMutation();

  const [sortedTasks, setSortedTasks] = useState([]);
  const [savedFilters, setSavedFilters] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterToEdit, setFilterToEdit] = useState(null);
  const [filterInput, setFilterInput] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedChip, setSelectedChip] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  useEffect(() => {
    setSavedFilters(taskFilters?.taskFilters || []);
  }, [tasks, taskFilters]);

  // Searchfield filter tasks
  useEffect(() => {
    if (!tasks) {
      setSortedTasks([]);
      return;
    }

    let filteredTasks = tasks.filter((task) => {
      const taskDate = task.dueDate
        ? new Date(task.dueDate).toLocaleDateString()
        : "";

      const searchableString =
        `${task.taskName} ${task.description} ${task.area} ${taskDate} ${task.responsibleUser} ${task.status}`.toLowerCase();

      return searchableString.includes(filterInput.toLowerCase());
    });

    setSortedTasks(filteredTasks);
  }, [tasks, filterInput]);

  const handleFilterInputChange = (event) => {
    setFilterInput(event.target.value);
  };

  const handleSaveFilter = () => {
    setSavedFilters(taskFilters?.taskFilters || []);
  };

  const handleApplySavedFilter = async (filter) => {
    try {
      const updatedFilters = savedFilters.map((f) => {
        if (f._id === filter._id) {
          return { ...f, activated: !f.activated };
        } else {
          return { ...f, activated: false };
        }
      });

      await replacetaskfilter({
        newTaskfiltersArray: updatedFilters,
      });

      dispatch(apiSlice.util.resetApiState());
    } catch (error) {
      console.error("Error applying filter:", error);
    }
  };

  const handleDeleteFilter = async (filter) => {
    const result = await deleteTaskFilter({ filterId: filter._id });
    dispatch(apiSlice.util.resetApiState());
    handleCloseMenu();
  };

  const handleOpenMenu = (event, filter) => {
    setAnchorEl(event.currentTarget);
    setSelectedChip(filter);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedChip(null);
  };

  const handleEditFilter = (filter) => {
    setFilterToEdit(filter);
    handleCloseMenu();
    setOpenFilter(true);
  };

  // Drag & Drop
  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const newStatus = columns.find(
      (column) => column.id === destination.droppableId
    )?.value;
    const draggableTaskIndex = sortedTasks.findIndex(
      (task) => task._id === draggableId
    );

    let newSortedTasks = Array.from(sortedTasks);
    const movedTask = {
      ...newSortedTasks[draggableTaskIndex],
      status: newStatus,
    };
    newSortedTasks.splice(draggableTaskIndex, 1);
    newSortedTasks.splice(destination.index, 0, movedTask);

    setSortedTasks(newSortedTasks);

    try {
      await updateTaskStatus({
        _id: draggableId,
        status: newStatus,
      }).unwrap();
    } catch (error) {
      console.error("Error updating task status:", error);
      setSortedTasks(sortedTasks);
    }
  };

  // open dialog
  const handleClickOpen = (task) => {
    if (task) {
      dispatch(setDialog({ content: "EditTaskForm", data: task }));
      return;
    }
    dispatch(setDialog({ content: "CreateTaskForm" }));
  };

  // Sort tasks!
  const handleSortChange = (sortOption) => {
    const newSortedTasks = sortBy(sortOption);
    setSortedTasks(newSortedTasks);
  };

  const sortBy = (sortOption) =>
    tasks.slice().sort((a, b) => {
      switch (sortOption) {
        case "newest":
          return new Date(b.dueDate) - new Date(a.dueDate);
        case "oldest":
          return new Date(a.dueDate) - new Date(b.dueDate);
        case "userAZ":
          return a.responsibleUser.localeCompare(b.responsibleUser);
        case "userZA":
          return b.responsibleUser.localeCompare(a.responsibleUser);
        case "areaAZ":
          return a.area.localeCompare(b.area);
        case "areaZA":
          return b.area.localeCompare(a.area);
        default:
          return 0;
      }
    });

  // Filter
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
    setFilterToEdit(null);
  };

  return (
    <div>
      <Stack
        direction={isMobile ? "column" : "row"}
        alignItems="center"
        justifyContent="space-between"
        style={{ marginLeft: "10px" }}
      >
        <Typography variant="h4" sx={{ mb: isMobile ? 2 : 5 }}>
          Aufgaben
        </Typography>
        <Button
          variant="contained"
          style={{
            marginRight: isMobile ? "0px" : "20px",
            textTransform: "none",
            width: isMobile ? "100%" : "auto",
            marginBottom: isMobile ? "10px" : "0px",
          }}
          onClick={() => handleClickOpen()}
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Aufgabe erstellen
        </Button>
      </Stack>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: isMobile ? "flex-start" : "space-between",
          marginBottom: "2%",
          gap: isMobile ? "10px" : "0px",
        }}
      >
        <StyledSearch
          value={filterInput}
          onChange={handleFilterInputChange}
          placeholder="Suche..."
          width={isMobile ? "100%" : "240"}
          startAdornment={
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{ color: "text.disabled", width: 20, height: 20 }}
              />
            </InputAdornment>
          }
        />
        <div style={{ textAlign: "right" }}>
          {savedFilters?.map((filter) => (
            <Chip
              key={filter._id}
              label={
                <Box display="flex" alignItems="center">
                  <span>{filter.filterName}</span>
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      handleOpenMenu(event, filter);
                    }}
                    style={{ marginLeft: "1px" }}
                  >
                    <Iconify icon="eva:more-vertical-outline" />
                  </IconButton>
                </Box>
              }
              onClick={() => handleApplySavedFilter(filter)}
              style={{
                marginRight: "2px",
                backgroundColor: filter.activated ? "#b0c4de" : "default",
                color: filter.activated ? "white" : "default",
              }}
            />
          ))}
          <TaskFilterSidebar
            openFilter={openFilter}
            onOpenFilter={handleOpenFilter}
            onCloseFilter={handleCloseFilter}
            handleSaveFilter={handleSaveFilter}
            filterToEdit={filterToEdit}
          />
          <TaskSort handleSortChange={handleSortChange} />
        </div>
      </div>

      {isMobile ? (
        <MobileAccordion
          tasks={sortedTasks}
          handleDeleteTask={handleClickOpen}
          handleClickOpen={handleClickOpen}
          isMobile={isMobile}
        />
      ) : (
        <div style={{ display: "flex" }}>
          <DragDropContext onDragEnd={onDragEnd}>
            {sortedTasks &&
              columns.map((column) => (
                <Column
                  key={column.id}
                  id={column.id}
                  title={column.title}
                  tasks={sortedTasks.filter(
                    (task) => task.status === column.value
                  )}
                  value={column.value}
                  color={column.color}
                  handleClickOpen={handleClickOpen}
                />
              ))}
          </DragDropContext>
        </div>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => handleEditFilter(selectedChip)}>
          <ListItemIcon>
            <Iconify icon="eva:edit-outline" />
          </ListItemIcon>
          Filter bearbeiten
        </MenuItem>
        <MenuItem onClick={() => handleDeleteFilter(selectedChip)}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" />
          </ListItemIcon>
          Filter löschen
        </MenuItem>
      </Menu>
    </div>
  );
}
