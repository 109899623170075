import React, { useState } from "react";
import PropTypes from "prop-types";
import { filter } from "lodash";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from "@mui/material";
import UserListToolbar from "./UserListToolbar";
import Scrollbar from "../../../components/scrollbar/Scrollbar";
import UserListHead from "./UserListHead";
import Label from "../../../components/label";
import Iconify from "../../../components/iconify";
import { useGetUsersQuery } from "../../../features/users/usersApiSlice";
import Loading from "../../../components/spinners/Loading";
import { showRoles } from "../../../utils/showRoles";
import { useDispatch } from "react-redux";
import { setDialog } from "../../../features/dialog/dialogSlice";

const TABLE_HEAD = [
  { id: "username", label: "Benutzer", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  // { id: "office", label: "Praxis", alignRight: false },
  { id: "roles", label: "Rechte", alignRight: false },
  { id: "officeId", label: "Praxis", alignRight: false },
  { id: "" },
];

// Utility functions
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(
      array,
      (_user) =>
        _user.username.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        (_user.firstName + " " + _user.lastName)
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }

  return stabilizedThis.map((el) => el[0]);
}

function NewUserListTable(props) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [user, setUser] = useState(null);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { data: userList, isLoading } = useGetUsersQuery();

  if (isLoading) {
    return <Loading />;
  }

  const handleEditUser = () => {
    dispatch(
      setDialog({
        title: "Benutzer bearbeiten",
        type: "EDIT",
        content: "create-user-form",
        data: user,
      })
    );
    handleCloseMenu();
  };

  const handleChangePassword = () => {
    dispatch(
      setDialog({
        type: "password-change",
        user: user,
      })
    );
    handleCloseMenu();
  };

  const handleDeleteUser = () => {
    dispatch(
      setDialog({
        content: "delete-user",
        data: user,
      })
    );
    handleCloseMenu();
  };

  const handleOpenMenu = (event, user) => {
    setUser(user);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setUser(null);
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((n) => n.username);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(
    userList,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredUsers.length && !!filterName;
  return (
    <Card>
      <UserListToolbar
        numSelected={selected.length}
        filterName={filterName}
        onFilterName={handleFilterByName}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={userList.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const { _id, username, firstName, lastName, roles, offices } =
                    row;
                  const selectedUser = selected.indexOf(username) !== -1;
                  console.log("offices", offices);
                  return (
                    <TableRow
                      hover
                      key={_id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={selectedUser}
                    >
                      <TableCell padding="checkbox">
                        <></>
                      </TableCell>

                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography variant="subtitle2" noWrap>
                            {username}
                          </Typography>
                        </Stack>
                      </TableCell>

                      <TableCell align="left">
                        {firstName + " " + lastName}
                      </TableCell>

                      <TableCell align="left">
                        {showRoles(roles) === "User" ? (
                          <Label color="success">{showRoles(roles)}</Label>
                        ) : showRoles(roles) === "Admin" ? (
                          <Label color="warning">{showRoles(roles)}</Label>
                        ) : (
                          <Label color="error">{showRoles(roles)}</Label>
                        )}
                      </TableCell>

                      <TableCell align="left">
                        {offices && offices.length > 0 ? (
                          offices.map((office, index) => (
                            <span key={index}>
                              {office.officeName}
                              {index < offices.length - 1 ? ", " : ""}
                            </span>
                          ))
                        ) : (
                          <strong>Keine Praxis</strong>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          size="large"
                          color="inherit"
                          onClick={(e) => handleOpenMenu(e, row)}
                        >
                          <Iconify icon={"eva:more-vertical-fill"} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {filteredUsers.length === 0 && (
                <TableRow
                  style={{ height: 53 * (rowsPerPage - userList.length) }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Paper
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography variant="h6" paragraph>
                        Nicht gefunden
                      </Typography>

                      <Typography variant="body2">
                        Keine Ergebnisse gefunden für &nbsp;
                        <strong>&quot;{filterName}&quot;</strong>.
                        <br /> Versuche es mit einem anderen Suchbegriff oder
                        überprüfe die Schreibweise.
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={userList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 180,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleEditUser()}>
          <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
          Bearbeiten
        </MenuItem>

        <MenuItem onClick={() => handleChangePassword()}>
          <Iconify icon={"carbon:password"} sx={{ mr: 2 }} />
          Passwort ändern
        </MenuItem>

        <MenuItem
          onClick={() => handleDeleteUser()}
          sx={{ color: "error.main" }}
        >
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Löschen
        </MenuItem>
      </Popover>
    </Card>
  );
}

NewUserListTable.propTypes = { props: PropTypes.object };

export default NewUserListTable;
