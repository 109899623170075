import { httpCommonAuth } from "../http.service";

const API_PATH = "/files";

export const downloadFile = (fileName) =>
  httpCommonAuth.get(`${API_PATH}/download/${fileName}`, {
    responseType: "arraybuffer",
  });

export const uploadFolderStructure = (formData, onProgress) => {
  return httpCommonAuth.post(`${API_PATH}/uploadfolderstructure/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentage = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      // Trigger the callback with the current progress
      if (onProgress) {
        onProgress(percentage);
      }
    },
  });
};

export const uploadFileAxios = (formData, fileId) => {
  return httpCommonAuth.post(`${API_PATH}/uploadfile/${fileId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadTenantLogoAxios = (formData) => {
  return httpCommonAuth.post(`tenant/upload-logo/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const downloadFileByID = (fileDataId) =>
  httpCommonAuth.get(`/filedata/downloadFileByID/${fileDataId}`, {
    responseType: "arraybuffer",
  });

export const downloadConvertedPdfFileByID = (fileDataId) =>
  httpCommonAuth.get(`/filedata/downloadConvertedPdfFileByID/${fileDataId}`, {
    responseType: "arraybuffer",
  });
