import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DocumentEditor from "../../sections/@dashboard/files/DocumentEditor/DocumentEditor";
import SpreadsheetEditor from "../../sections/@dashboard/files/DocumentEditor/SpreadsheetEditor";
import PdfViewer from "../../sections/@dashboard/files/DocumentEditor/PdfViewer";
import {
  downloadFileByID,
  uploadFileAxios,
} from "../../app/api/axios/services/files.service";
import { useReleaseDocumentMutation } from "../../features/fileData/fileDataApiSlice";
import { useDispatch } from "react-redux";
import { setDialog } from "../../features/dialog/dialogSlice";
import ErrorAlert from "../alerts/ErrorAlert";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { useForm } from "react-hook-form";
import ReleaseDocumentForm from "../forms/Forms/ReleaseDocumentForm";

export default function ReleaseDocWithPreviewDialog({ onClose, dialog }) {
  const dispatch = useDispatch();
  const hasFetched = React.useRef(false);
  const [file, setFile] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [releaseDocument, { isError }] = useReleaseDocumentMutation();
  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      recallAmount: "1",
      recallPeriod: "years",
    },
  });
  const revisionChecked = watch("revisionDoctor");
  const checkCreateProof = watch("checkCreateProof");

  const documentEditorRef = React.useRef(null);
  const handleClose = () => {
    onClose();
  };

  const handleReleaseFile = async (docFormData, formData) => {
    const { selectedFolder } = formData;
    if (selectedFolder) {
      formData.proofFolder = selectedFolder[selectedFolder.length - 1]?.id;
      delete formData.selectedFolder;
    }

    return await Promise.all([
      releaseDocument({
        _id: dialog.data.fileData._id,
        ...formData,
      }).unwrap(),
      uploadFileAxios(docFormData, dialog.data.fileData._id),
    ]);
  };

  const handleSaveResult = (resultFileData, resultUploadFile) => {
    setLoading(false);

    if (resultFileData.type === "success" && resultUploadFile?.status === 200) {
      onClose("REFETCH");
      dispatch(
        setDialog({
          title: `${dialog.data.fileData.fileName} wurde erfolgreich gespeichert!`,
          type: "SUCCESS",
          data: {
            successText: resultFileData?.message,
          },
        })
      );
    } else {
      window.alert(
        "Es gab ein Problem beim Speichern der Datei.\nBitte versuchen Sie es erneut."
      );
    }
  };

  const handleSave = async (formData) => {
    try {
      const exportedDocument =
        await documentEditorRef.current.documentEditor.saveAsBlob("Docx");
      const docFormData = new FormData();
      docFormData.append("fileName", dialog.data.fileData.fileName);
      docFormData.append("file", exportedDocument);

      if (!documentEditorRef.current) {
        setLoading(false);
        return;
      }

      let resultFileData, resultUploadFile;

      [resultFileData, resultUploadFile] = await handleReleaseFile(
        docFormData,
        formData
      );

      handleSaveResult(resultFileData, resultUploadFile);
    } catch (error) {
      console.error("Error uploading file:", error.message);
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    console.log("data :>> ", data);
    try {
      await handleSave(data);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  React.useEffect(() => {
    if (hasFetched.current) return;
    async function fetchData() {
      try {
        let f = await downloadFileByID(dialog.data.fileData._id); // Download file with id
        setFile(f);
      } catch (error) {
        console.log("error :>> ", error);
      }
    }
    // Only fetch data if dialog.data.fileData._id exists
    if (dialog.data.fileData._id) {
      fetchData();
    }
    hasFetched.current = true; // Set the flag to true after the effect runs
  }, [dialog.data.fileData._id]); // Dependency array with the file ID

  return (
    <Dialog maxWidth="xl" open onClose={handleClose}>
      <DialogTitle>{dialog?.title ?? ""}</DialogTitle>
      <DialogContent>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <div style={{ minWidth: 600, minHeight: "70vh" }}>
            {dialog.data.fileData.fileName.includes(".doc") && file && (
              <DocumentEditor
                documentEditorRef={documentEditorRef}
                data={dialog?.data}
                file={file}
                preview={true}
              />
            )}
            {dialog.data.fileData.fileName.includes(".xls") && (
              <SpreadsheetEditor data={dialog?.data} />
            )}
            {dialog.data.fileData.fileName.includes(".pdf") && (
              <PdfViewer data={dialog?.data} />
            )}
          </div>
          <div>
            <div style={{ maxWidth: 715 }}>
              <form>
                <ReleaseDocumentForm
                  control={control}
                  revisionChecked={revisionChecked}
                  checkCreateProof={checkCreateProof}
                />
                {isError && (
                  <div style={{ marginTop: 15 }}>
                    <ErrorAlert errorMessage="Fehler beim erstellen des Dokuments" />
                  </div>
                )}
              </form>
            </div>
          </div>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          autoFocus
          onClick={handleSubmit(onSubmit)}
        >
          {"FREIGEBEN"}
        </Button>
        <Button onClick={handleClose} disabled={loading} color="primary">
          Beenden
        </Button>
      </DialogActions>
    </Dialog>
  );
}
