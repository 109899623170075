import { Grid, Typography } from "@mui/material";
import ControlledToggleButtonGroup from "../../../../../components/forms/ControlledToggleButtonGroup";
import ControlledSelectField from "../../../../../components/forms/ControlledSelectField";
import TextField from "../../../../../components/forms/TextField";
import DEDateField from "../../../../../components/forms/DEDateField";
import ControlledCheckbox from "../../../../../components/forms/ControlledCheckbox";
import ControlledRadioButton from "../../../../../components/forms/ControlledRadioButton";

export function Step1MandatoryFields({ control, typeOfEntry }) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Bitte füllen Sie die folgenden Pflichtfelder aus, um einen neuen
          Eintrag zu erstellen.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ControlledToggleButtonGroup
          control={control}
          name="incidentType"
          fullWidth
          options={[
            { label: "Fehler", value: "fehler" },
            { label: "Beschwerde", value: "beschwerde" },
            { label: "Risiko", value: "risiko" },
          ]}
        />
      </Grid>
      <Grid item xs={6}>
        <ControlledSelectField
          control={control}
          name="classification"
          label="Einstufung"
          required={true}
          itemArray={[
            { label: "leicht", value: "leicht" },
            { label: "mittel", value: "mittel" },
            { label: "schwer", value: "schwer" },
          ]}
        />
      </Grid>
      <Grid item xs={6}>
        <ControlledSelectField
          control={control}
          name="frequency"
          label={
            typeOfEntry === "risiko"
              ? "Eintrittswahrscheinlichkeit"
              : "Häufigkeit des Vorfalls"
          }
          required={true}
          itemArray={[
            {
              label: typeOfEntry === "risiko" ? "gering" : "erstmalig",
              value: typeOfEntry === "risiko" ? "gering" : "erstmalig",
            },
            {
              label: typeOfEntry === "risiko" ? "mittel" : "selten",
              value: typeOfEntry === "risiko" ? "mittel" : "selten",
            },
            {
              label: typeOfEntry === "risiko" ? "hoch" : "häufig",
              value: typeOfEntry === "risiko" ? "hoch" : "haeufig",
            },
          ]}
        />
      </Grid>
      <Grid item xs={8}>
        <TextField
          control={control}
          name="title"
          label="Titel"
          required={true}
          fullWidth
        />
      </Grid>

      <Grid item xs={4}>
        <DEDateField
          control={control}
          name="recognisedOn"
          label="Erkannt am:"
          required={true}
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          control={control}
          name="description"
          label="Beschreibung"
          helperText={
            typeOfEntry === "risiko"
              ? "Beschreiben Sie das Risiko detailliert."
              : "Was ist passiert? Beschreiben Sie den Vorfall detailliert."
          }
          required={true}
          multiline
          fullWidth
        />
      </Grid>
      <Grid item xs={5.5} sx={{ ml: 2 }}>
        <ControlledRadioButton
          control={control}
          name="scope"
          label="Geltungsbereich"
          itemArray={[
            {
              label: "Öffentlich",
              value: true,
            },
            {
              label: "Nur diese Praxis",
              value: false,
            },
          ]}
        />
      </Grid>
      <Grid item xs={6}>
        Möchten Sie den Eintrag anonym erstellen?
        <ControlledCheckbox
          name="anonym"
          control={control}
          label="Anonym erstellen"
        />
      </Grid>
    </Grid>
  );
}
