import * as React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  ListItemText,
  ListItemButton,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import { fToNow } from "../../../../utils/formatTime";
import Iconify from "../../../../components/iconify";
import { AvatarIntern } from "../../../../components/avatar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDialog } from "../../../../features/dialog/dialogSlice";

import {
  useDeleteNoteMutation,
  useUpdateNoteMutation,
} from "src/features/notes/notesApiSlice";

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date).isRequired,
    _id: PropTypes.string.isRequired,
    isUnRead: PropTypes.bool,
    message: PropTypes.string,
    link: PropTypes.string,
    description: PropTypes.string,
    sender: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }).isRequired,
};

export default function NotificationItem({
  notification,
  currentUserId,
  isUnRead,
  onDelete,
  onUpdateStatus,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteNotification] = useDeleteNoteMutation();
  const [updateNotificationStatus] = useUpdateNoteMutation();

  const handleItemClick = () => {
    const [basepath, path, noteIdPath] = notification.link.split("/").slice(-3);
    const endPath = "/dashboard/" + path;
    navigate(endPath);
  };

  const handleMenuClick = (event) => {
    event.stopPropagation(); // Verhindert, dass das ListItemButton-Ereignis ausgelöst wird
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      await deleteNotification({ id: notification._id }).unwrap();
      onDelete(notification._id);
      handleMenuClose();
    } catch (error) {
      console.error("Failed to delete the notification:", error);
    }
  };

  const handleToggleReadStatus = async () => {
    try {
      await updateNotificationStatus({
        id: notification._id,
        userId: currentUserId,
      }).unwrap();
      onUpdateStatus(notification._id, currentUserId);
      handleMenuClose();
    } catch (error) {
      console.error("Failed to update the notification status:", error);
    }
  };

  return (
    <>
      <ListItemButton
        onClick={handleItemClick}
        sx={{
          py: 1.5,
          px: 2.5,
          mt: "1px",
          ...(notification.isUnRead && {
            bgcolor: "action.selected",
          }),
        }}
      >
        <IconButton
          sx={{
            p: 0,
            mr: "10px",
          }}
        >
          <AvatarIntern
            name={
              notification?.sender?.firstName +
              " " +
              notification?.sender?.lastName
            }
          />
        </IconButton>
        <ListItemText
          primary={
            <Typography variant="subtitle2">
              {notification.message}
              <Typography
                component="span"
                variant="body2"
                sx={{ color: "text.secondary" }}
              >
                &nbsp; {notification.description}
              </Typography>
            </Typography>
          }
          secondary={
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: "flex",
                alignItems: "center",
                color: "text.disabled",
              }}
            >
              <Iconify
                icon="eva:clock-outline"
                sx={{ mr: 0.5, width: 16, height: 16 }}
              />
              {fToNow(notification.createdAt)}
            </Typography>
          }
        />
        <IconButton onClick={handleMenuClick}>
          <Iconify icon="eva:more-vertical-outline" width={16} height={20} />
        </IconButton>
      </ListItemButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleToggleReadStatus}>
          <ListItemIcon>
            <Iconify
              icon={
                isUnRead
                  ? "eva:checkmark-circle-2-outline"
                  : "eva:slash-outline"
              }
              width={20}
              height={20}
            />
          </ListItemIcon>
          {isUnRead ? "Als gelesen markieren" : "Als ungelesen markieren"}
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={20} height={20} />
          </ListItemIcon>
          Löschen
        </MenuItem>
      </Menu>
    </>
  );
}
