import PropTypes from "prop-types";
import { alpha, useTheme, styled } from "@mui/material/styles";
import { Card, Typography, Grid, Box } from "@mui/material";
// components
import Iconify from "../../../components/iconify";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

const StyledIcon = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(6),
  height: theme.spacing(6),
  justifyContent: "center",
  marginBottom: theme.spacing(2),
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2),
}));

// ----------------------------------------------------------------------

AppWidgetFourSummary.propTypes = {
  parts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
      icon: PropTypes.string.isRequired,
      color: PropTypes.string,
    })
  ).isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetFourSummary({ parts, routeTo, sx, ...other }) {
  const navigate = useNavigate();
  const theme = useTheme(); // Use useTheme to access theme object

  return (
    <Card
      sx={{
        py: 0,
        boxShadow: 0,
        textAlign: "center",
        cursor: "pointer",
        color: theme.palette.primary.darker,
        bgcolor: theme.palette.primary.lighter,
        ...sx,
      }}
      {...other}
      onClick={() => navigate(routeTo)}
    >
      <Grid container spacing={0}>
        {parts.map((part, index) => {
          // Use custom color or theme color
          const backgroundColor = part.color || theme.palette.primary.lighter;
          const textColor = part.color
            ? theme.palette.getContrastText(part.color)
            : theme.palette.primary.darker;

          return (
            <Grid item xs={6} key={index}>
              <StyledBox
                sx={{
                  color: textColor,
                  bgcolor: backgroundColor,
                }}
              >
                <StyledIcon
                  sx={{
                    color: textColor,
                    backgroundImage: `linear-gradient(135deg, ${alpha(
                      textColor,
                      0
                    )} 0%, ${alpha(textColor, 0.24)} 100%)`,
                  }}
                >
                  <Iconify icon={part.icon} width={24} height={24} />
                </StyledIcon>

                <Typography variant="h4">{part.total}</Typography>

                <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                  {part.title}
                </Typography>
              </StyledBox>
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
}
