import React from "react";
import { useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  Divider,
  Button,
  useMediaQuery,
} from "@mui/material";
import Iconify from "../../../../components/iconify";
import ControlledSelectField from "../../../../components/forms/ControlledSelectField";
import TextField from "../../../../components/forms/TextField";
import DEDateField from "../../../../components/forms/DEDateField";
import SelectController from "../../../../components/forms/SelectController";
import ControlledCheckbox from "../../../../components/forms/ControlledCheckbox";
import ControlSection from "./ControlSection";
import { setDialog } from "src/features/dialog/dialogSlice";
import { useTheme } from "@emotion/react";
import ControlledRadioButton from "../../../../components/forms/ControlledRadioButton";

export default function FbDataForm({
  selectedItem,
  control,
  append,
  fields,
  kontrolleFields,
  meetingContent,
  handleRiskManagement,
  handleRemove,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const handleDeleteMeasure = (entryId, measureID, index) => {
    dispatch(
      setDialog({
        content: "DeleteForm",
        data: { entryId, measureID, index },
        art: "DELETEFBRMEAUSURE",
        handleRemove,
      })
    );
  };

  return (
    <Grid
      container
      spacing={1}
      sx={{ mt: 2, mb: 15 }}
      justifyContent="space-between"
    >
      {selectedItem?.incidentToRisk && (
        <Grid item xs={12}>
          <Typography
            variant="h3"
            sx={{ fontWeight: "bold", textAlign: "left", marginY: 1 }}
          >
            <Iconify
              icon="mdi:done-outline"
              sx={{
                height: "1em",
                width: "1em",
                color: "green",
                mr: "20px",
              }}
            />
            Ins Risikomanagement aufgenommen!
          </Typography>
        </Grid>
      )}
      <Grid item xs={isMobile ? 12 : 3}>
        <Typography
          variant="h3"
          sx={{ fontWeight: "bold", textAlign: "left", marginY: 1 }}
        >
          {selectedItem &&
            selectedItem.incidentType &&
            selectedItem.incidentType.charAt(0).toUpperCase() +
              selectedItem.incidentType.slice(1).toLowerCase()}
        </Typography>
      </Grid>
      <Grid item xs={isMobile ? 12 : 3}>
        <ControlledSelectField
          control={control}
          name="status"
          label="Status"
          required={true}
          itemArray={[
            { label: "Offen", value: "offen" },
            { label: "In Bearbeitung", value: "in Bearbeitung" },
            { label: "Abgeschlossen", value: "abgeschlossen" },
          ]}
          disabled={selectedItem?.incidentToRisk}
        />
      </Grid>
      <Grid item xs={isMobile ? 12 : 3}>
        <ControlledSelectField
          control={control}
          name="classification"
          label="Einstufung"
          required={true}
          itemArray={[
            { label: "leicht", value: "leicht" },
            { label: "mittel", value: "mittel" },
            { label: "schwer", value: "schwer" },
          ]}
          disabled={selectedItem?.incidentToRisk}
        />
      </Grid>
      <Grid item xs={isMobile ? 12 : 3}>
        <ControlledSelectField
          control={control}
          name="frequency"
          label="Häufigkeit des Vorfalls"
          required={true}
          itemArray={[
            {
              label: "erstmalig",
              value: "erstmalig",
            },
            {
              label: "selten",
              value: "selten",
            },
            {
              label: "häufig",
              value: "haeufig",
            },
          ]}
          disabled={selectedItem?.incidentToRisk}
        />
      </Grid>
      <Grid item xs={isMobile ? 12 : 8}>
        <TextField
          control={control}
          name="title"
          label="Titel"
          required={true}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          disabled={selectedItem?.incidentToRisk}
        />
      </Grid>
      <Grid item xs={isMobile ? 12 : 4}>
        <DEDateField
          control={control}
          name="recognisedOn"
          label="Erkannt am:"
          required={true}
          fullWidth
          disabled={selectedItem?.incidentToRisk}
        />
      </Grid>
      <Grid item xs={isMobile ? 12 : 8}>
        <TextField
          control={control}
          name="description"
          label="Beschreibung"
          required={true}
          multiline
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          disabled={selectedItem?.incidentToRisk}
        />
      </Grid>
      <Grid item xs={isMobile ? 12 : 4}>
        <SelectController
          control={control}
          name="responsibleUser"
          label="Zugewiesen an:"
          assignToMeQ={false}
          disabled={selectedItem?.incidentToRisk}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ mt: 1, mb: 1 }} />
      </Grid>

      {fields.map((measure, index) => (
        <React.Fragment key={measure.id}>
          <Grid item xs={index !== 0 ? 7 : 8}>
            <TextField
              control={control}
              name={`measure[${index}].content`}
              label={
                index === 0 ? "Sofortmaßnahme" : `Korrekturmaßnahme ${index}`
              }
              fullWidth
              multiline
              InputLabelProps={{
                shrink: true,
              }}
              disabled={selectedItem?.incidentToRisk}
            />
          </Grid>
          <Grid item xs={4}>
            <DEDateField
              control={control}
              name={`measure[${index}].date`}
              label={
                index === 0
                  ? "Datum Sofortmaßname"
                  : `Datum Korrekturmaßname ${index}`
              }
              fullWidth
              shrinkInputLabel="true"
              disabled={selectedItem?.incidentToRisk}
            />
          </Grid>
          {index !== 0 && (
            <Grid item xs={1}>
              <Button
                sx={{ height: "100%" }}
                disabled={selectedItem?.incidentToRisk}
                onClick={() =>
                  handleDeleteMeasure(selectedItem?._id, measure?._id, index)
                }
              >
                <Iconify
                  icon="mdi:delete"
                  sx={{
                    height: "25px",
                    width: "25px",
                    color: "red",
                  }}
                />
              </Button>
            </Grid>
          )}
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={() => append({})}
          startIcon={<Iconify icon="tabler:plus" />}
          disabled={selectedItem?.incidentToRisk}
          sx={{ textTransform: "none" }}
        >
          Maßnahme hinzufügen
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TextField
          control={control}
          name="causeAnalysis"
          label="Ursachenanalyse"
          multiline
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          disabled={selectedItem?.incidentToRisk}
        />
      </Grid>

      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12} md={4}>
          <ControlledRadioButton
            control={control}
            name="scope"
            label="Geltungsbereich"
            itemArray={[
              {
                label: "Öffentlich",
                value: true,
              },
              {
                label: "Nur diese Praxis",
                value: false,
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ControlledCheckbox
            name="discussionInTeamMeetingContent"
            control={control}
            label="Besprechung in Teammeeting"
            disabled={selectedItem?.incidentToRisk}
          />
        </Grid>
        {meetingContent && (
          <Grid item xs={12} md={4}>
            <DEDateField
              control={control}
              name="discussionInTeamMeetingDate"
              label="Datum Besprechung"
              fullWidth
              shrinkInputLabel="true"
              disabled={selectedItem?.incidentToRisk}
            />
          </Grid>
        )}
      </Grid>

      {kontrolleFields.length > 0 && (
        <ControlSection
          kontrolleFields={kontrolleFields}
          control={control}
          selectedItem={selectedItem}
          handleRiskManagement={handleRiskManagement}
        />
      )}
    </Grid>
  );
}
