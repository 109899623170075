import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Link,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Divider,
} from "@mui/material";
import Iconify from "../../../components/iconify";

const lawsData = [
  {
    title: "Allgemeines Gleichbehandlungsgesetz (AGG)",
    content: "Gesetz zum Schutz vor Diskriminierung.",
    link: "", // Keine Datei verfügbar
    url: "www.gesetze-im-internet.de/agg",
    highlight: "",
    favorite: false,
  },
  {
    title: "Arbeitsgerichtsgesetz (ArbGG)",
    content:
      "Gesetz über die Errichtung und Zuständigkeit der Arbeitsgerichte.",
    link: "", // Keine Datei verfügbar
    url: "www.gesetze-im-internet.de/arbgg/",
    highlight: "#d9f3f2",
    favorite: true,
  },
  {
    title: "Arbeitszeitgesetz (ArbZG)",
    content: "Regelungen zu Arbeits- und Ruhezeiten für Arbeitnehmer.",
    link: "", // Keine Datei verfügbar
    url: "www.gesetze-im-internet.de/arbzg/",
    highlight: "",
    favorite: false,
  },
  {
    title: "Bürgerliches Gesetzbuch (BGB)",
    content: "Zivilrechtliche Vorschriften in Deutschland.",
    link: "", // Keine Datei verfügbar
    url: "www.gesetze-im-internet.de/bgb/",
    highlight: "",
    favorite: true,
  },
  {
    title: "Jugendarbeitsschutzgesetz (JArbSchG)",
    content: "Schutzbestimmungen für die Beschäftigung Jugendlicher.",
    link: "", // Keine Datei verfügbar
    url: "www.gesetze-im-internet.de/jarbschg/",
    highlight: "",
    favorite: false,
  },
  {
    title: "Kinderarbeitsschutzverordnung (KindArbSchV)",
    content: "Bestimmungen zum Schutz vor Kinderarbeit.",
    link: "", // Keine Datei verfügbar
    url: "www.gesetze-im-internet.de/kindarbschv/",
    highlight: "",
    favorite: false,
  },
  {
    title: "Gesetz über den Ladenschluss (LadSchlG)",
    content: "Bestimmungen zum Ladenschluss in Deutschland.",
    link: "", // Keine Datei verfügbar
    url: "www.gesetze-im-internet.de/ladschlg/",
    highlight: "",
    favorite: false,
  },
  {
    title: "Mutterschutzgesetz (MuSchG)",
    content:
      "Schutzbestimmungen für schwangere und stillende Arbeitnehmerinnen.",
    link: "/documents/MuSchG.pdf",
    url: "www.gesetze-im-internet.de/muschg_2018/",
    highlight: "#d9f3f2",
    favorite: false,
  },
  {
    title:
      "Verordnung über den Verkauf bestimmter Waren an Sonn- und Feiertagen (SonntVerkV)",
    content: "Regelungen zum Verkauf bestimmter Waren an Sonn- und Feiertagen.",
    link: "", // Keine Datei verfügbar
    url: "www.gesetze-im-internet.de/sonntverkv/",
    highlight: "",
    favorite: false,
  },
  {
    title: "Sozialgesetzbuch (SGB)",
    content: "Grundlagen des Sozialrechts in Deutschland.",
    link: "/documents/SGB.pdf",
    url: "www.sozialgesetzbuch-sgb.de",
    highlight: "#d9f3f2",
    favorite: true,
  },
  {
    title: "Strahlenschutzverordnung (StrlSchV)",
    content: "Regelungen zum Schutz vor ionisierender Strahlung.",
    link: "", // Keine Datei verfügbar
    url: "www.gesetze-im-internet.de/strlschv_2018/",
    highlight: "",
    favorite: false,
  },
  {
    title: "Biostoffverordnung (BioStoffV)",
    content: "Regelungen zum Schutz vor biologischen Arbeitsstoffen.",
    link: "", // Keine Datei verfügbar
    url: "www.gesetze-im-internet.de/biostoffv_2013/",
    highlight: "",
    favorite: false,
  },
  {
    title: "Gefahrstoffverordnung (GstoffV)",
    content: "Bestimmungen zum Umgang mit Gefahrstoffen.",
    link: "", // Keine Datei verfügbar
    url: "www.gesetze-im-internet.de/gefstoffv_2010/",
    highlight: "",
    favorite: false,
  },
  {
    title: "Arzneimittelgesetz (AMG)",
    content: "Regelungen zur Zulassung und Überwachung von Arzneimitteln.",
    link: "", // Keine Datei verfügbar
    url: "www.gesetze-im-internet.de/amg_1976/",
    highlight: "",
    favorite: false,
  },
  {
    title: "Gendiagnostikgesetz (GenDG)",
    content: "Gesetzliche Regelungen zur Gendiagnostik.",
    link: "", // Keine Datei verfügbar
    url: "www.gesetze-im-internet.de/gendg/",
    highlight: "",
    favorite: false,
  },
  {
    title: "Infektionsschutzgesetz (IfSG)",
    content: "Regelungen zum Schutz vor übertragbaren Krankheiten.",
    link: "", // Keine Datei verfügbar
    url: "www.gesetze-im-internet.de/ifsg/",
    highlight: "",
    favorite: false,
  },
  {
    title:
      "Verordnung über die Berufsausbildung zum Medizinischen Fachangestellten (MedFAngAusbV)",
    content:
      "Bestimmungen zur Berufsausbildung von Medizinischen Fachangestellten.",
    link: "", // Keine Datei verfügbar
    url: "www.gesetze-im-internet.de/medfangausbv/",
    highlight: "",
    favorite: false,
  },
  {
    title: "Mess- und Eichverordnung (MessEV)",
    content: "Regelungen zur Eichung und Messgenauigkeit.",
    link: "", // Keine Datei verfügbar
    url: "www.gesetze-im-internet.de/messev/",
    highlight: "",
    favorite: false,
  },
  {
    title: "Medizinprodukte-Abgabeverordnung (MPAV)",
    content: "Bestimmungen zur Abgabe von Medizinprodukten.",
    link: "", // Keine Datei verfügbar
    url: "www.gesetze-im-internet.de/mpav/",
    highlight: "",
    favorite: false,
  },
  {
    title: "Medizinprodukte-Betreiberverordnung (MPBetreibV)",
    content: "Regelungen zur Sicherheit im Betrieb von Medizinprodukten.",
    link: "", // Keine Datei verfügbar
    url: "www.gesetze-im-internet.de/mpbetreibv/",
    highlight: "",
    favorite: false,
  },
  {
    title: "Medizinproduktegesetz (MPG)",
    content: "Regelungen zur Sicherheit und Kontrolle von Medizinprodukten.",
    link: "", // Keine Datei verfügbar
    url: "www.gesetze-im-internet.de/mpg/",
    highlight: "",
    favorite: false,
  },
  {
    title: "Produktsicherheitsgesetz (ProdSG)",
    content: "Regelungen zur Produktsicherheit und Gefahrenabwehr.",
    link: "", // Keine Datei verfügbar
    url: "www.gesetze-im-internet.de/prodsg_2021/",
    highlight: "",
    favorite: false,
  },
  {
    title: "Transfusionsgesetz (TFG)",
    content: "Regelungen zur Durchführung von Bluttransfusionen.",
    link: "", // Keine Datei verfügbar
    url: "www.gesetze-im-internet.de/tfg/",
    highlight: "",
    favorite: false,
  },
  {
    title: "Technische Regeln für Biologische Arbeitsstoffe (TRBA250)",
    content:
      "Sicherheitsregeln für den Umgang mit biologischen Arbeitsstoffen.",
    link: "", // Keine Datei verfügbar
    url: "https://www.bgw-online.de/resource/blob/13696/f7d59061152e53c1b6a32ab254f6acd5/trba250-biologische-arbeitsstoffe-im-gesundheitswesen-und-in-der-wohlfahrtspflege-data.pdf",
    highlight: "",
    favorite: false,
  },
];

export default function LawAccordion() {
  return (
    <Card sx={{ width: "100%", mt: 4 }}>
      <CardContent sx={{ p: 0 }}>
        {lawsData.map((law, index) => (
          <Accordion
            key={index}
            sx={{
              width: "100%",
              backgroundColor: law.highlight || "transparent",
              m: 0,
            }}
          >
            <AccordionSummary
              expandIcon={<Iconify icon="ic:round-expand-more" />}
            >
              <Typography variant="h6">{law.title}</Typography>
              {law.favorite && (
                <Iconify icon="ic:round-star" sx={{ ml: 2, color: "gold" }} />
              )}
            </AccordionSummary>

            <AccordionDetails sx={{ backgroundColor: "white" }}>
              <Typography>{law.content}</Typography>
              <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                {law.link && (
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <Iconify icon="ic:outline-description" />
                    <Link href={law.link} target="_blank" rel="noopener">
                      Dokument anzeigen
                    </Link>
                  </Stack>
                )}
                {law.url && (
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Tooltip title={law.url}>
                      <Iconify
                        icon="ic:baseline-public"
                        sx={{ cursor: "pointer" }}
                      />
                      <Link
                        href={`https://${law.url}`}
                        target="_blank"
                        rel="noopener"
                        sx={{ cursor: "pointer", ml: 0.5 }}
                      >
                        Website öffnen
                      </Link>
                    </Tooltip>
                  </Stack>
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
        ))}
      </CardContent>
    </Card>
  );
}
