import React from "react";
import PropTypes from "prop-types";
import { Button, Grid, MenuItem, Paper, TextField } from "@mui/material";
import Iconify from "../../../components/iconify";
import { surveySelectItems } from "../../../utils/surveyUtils";

SurveyFormQuestionItems.propTypes = {
  fields: PropTypes.array,
  register: PropTypes.func,
  remove: PropTypes.func,
};

function SurveyFormQuestionItems({ fields, remove, register, watchQuestions }) {
  // ADD WATCH ENTRY TO ADD SINGLE/MULTIPLE CHOICE ANSWERS

  console.log("watchQuestions :>> ", watchQuestions);

  const handleOptionSelect = (option) => {
    console.log("option :>> ", option);
  };

  const createSelectValueArrays = () => {
    return surveySelectItems.map((option) => (
      <MenuItem
        key={option.value}
        value={option.value}
        onClick={() => handleOptionSelect(option)}
      >
        {option.label}
      </MenuItem>
    ));
  };
  console.log("fields :>> ", fields);

  return fields.map((surveyQuestions, index) => (
    <React.Fragment key={surveyQuestions.id}>
      <Grid item xs={6}>
        <TextField
          {...register(`surveyQuestions.${index}.question`, { required: true })}
          name={`surveyQuestions.${index}.question`}
          label={`Frage ${index + 1}`}
          fullWidth
          multiline
          required
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={5}>
        <TextField
          select
          fullWidth
          variant="outlined"
          // sx={{ minWidth: 250 }}
          {...register(`surveyQuestions.${index}.type`, { required: true })}
          name={`surveyQuestions.${index}.type`}
          label="Antwortmöglichkeiten:"
          errorMsg="Bitte Art auswählen."
          required={true}
        >
          <MenuItem value="" disabled>
            Art der Bewertung auswählen
          </MenuItem>
          {createSelectValueArrays()}
        </TextField>
      </Grid>
      <Grid item xs={1}>
        <Button variant="contained" onClick={() => remove(index)} color="error">
          <Iconify
            icon="mdi:delete"
            sx={{
              m: 1,
              height: "25px",
              width: "25px",
            }}
          />
        </Button>
      </Grid>
      {watchQuestions[index]?.rating === "multipleChoice" && (
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }} elevation={1}>
            Hier entsteht ein Feld bei dem Antworten eingegeben werden können
          </Paper>
        </Grid>
      )}
    </React.Fragment>
  ));
}

export default SurveyFormQuestionItems;
