import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Button,
  Container,
  Typography,
  useMediaQuery,
  Stack,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectCurrentUserData,
} from "../features/auth/authSlice";
import TextField from "../components/forms/TextField";
import account from "../_mock/account";
import useAuth from "../hooks/useAuth";
import UserRolesBadge from "../components/rolesbadge/UserRolesBadge";
import DEDateField from "../components/forms/DEDateField";
import { useUpdateUserdataAsUserMutation } from "../features/users/usersApiSlice";
import { AvatarIntern } from "../components/avatar";

export default function ProfilePage() {
  const currentUserData = useSelector(selectCurrentUserData);
  const currentUserMail = useSelector(selectCurrentUser);
  const { status } = useAuth();
  const [updateUserDataAsUser, { isLoading, isSuccess, isError }] =
    useUpdateUserdataAsUserMutation();

  console.log("currentUserData :>> ", currentUserData);
  console.log("status :>> ", status);

  const { handleSubmit, control } = useForm({
    defaultValues: {
      ...currentUserData,
      mail: currentUserMail,
    },
  });
  const [profileImage, setProfileImage] = useState(account.photoURL);
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onSubmit = async (data) => {
    console.log("data :>> ", data);

    const uD = {
      mail: currentUserMail,
      firstName: data.firstName,
      lastName: data.lastName,
      birthdate: data.birthdate.$d,
      phone: data.phone,
    };

    try {
      // Dispatch the update user mutation
      const result = await updateUserDataAsUser(uD).unwrap();

      // Handle success
      console.log("User updated successfully:", result);
    } catch (error) {
      // Handle error
      console.error("Error updating user:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>{"Mojave QM"} | Mein Profil</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4">Mein Profil</Typography>
        </Stack>
      </Container>
      <Container>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "70vh" }}
        >
          <div
            style={{
              padding: "20px",
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
              // alignItems: 'center',
              gap: "16px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
                id="profile-image-upload"
              />
              {/* <label htmlFor="profile-image-upload"> */}
              <AvatarIntern
                name={`${currentUserData.firstName} ${currentUserData.lastName}`}
                style={{
                  width: 160,
                  height: 160,
                  cursor: "pointer",
                  "&:hover": { opacity: 0.8 },
                }}
              />
              {/* <Avatar
                src={profileImage}
                alt="photoURL"
                sx={{ width: 160, height: 160, cursor: 'pointer', '&:hover': { opacity: 0.8 } }}
              /> */}
              <UserRolesBadge status={status} />
            </div>

            <form onSubmit={handleSubmit(onSubmit)} style={{ flex: 1 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <TextField
                    control={control}
                    name="firstName"
                    label="Vorname"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    control={control}
                    name="lastName"
                    label="Nachname"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <DEDateField
                    control={control}
                    name="birthdate"
                    label="Geburtsdatum"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    control={control}
                    name="mail"
                    label="E-Mail-Adresse"
                    variant="outlined"
                    type="email"
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    control={control}
                    name="phone"
                    label="Rufnummer"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  type="submit"
                >
                  Speichern
                </Button>
              </div>
            </form>
          </div>
          <Grid item xs={12}>
            {/* Display loading, success, or error messages */}
            {isLoading && <p>Loading...</p>}
            {isSuccess && <p>User updated successfully!</p>}
            {isError && <p>Error updating user. Please try again.</p>}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
