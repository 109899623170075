import * as React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import TextField from "../../../components/forms/TextField";

// ----------------------------------------------------------------------------

NewsForm.propTypes = {
  control: PropTypes.object.isRequired,
};

// ----------------------------------------------------------------------------

export default function NewsForm({ control }) {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            control={control}
            name="title"
            label="Titel"
            errorMsg="Bitte geben Sie einen Titel ein!"
            fullWidth
            required={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            control={control}
            name="subtitle"
            label="Untertitel"
            variant="outlined"
            errorMsg="Bitte geben Sie einen Untertitel ein!"
            multiline
            fullWidth
            required={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            control={control}
            name="content"
            label="Inhalt"
            variant="outlined"
            errorMsg="Bitte geben Sie den Inhalt ein!"
            multiline
            fullWidth
            required={true}
          />
        </Grid>
      </Grid>
    </div>
  );
}
