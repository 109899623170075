// SimpleDialog.js
import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { clearDialog } from "../../features/dialog/dialogSlice";
import FileUploadDialog from "../../sections/@dashboard/files/FileUploadDialog";
import {
  CreateTaskDialog,
  EditTaskDialog,
} from "../../sections/@dashboard/tasks";
import { EventDialog } from "../../sections/@dashboard/events";
import OfficeFormDialog from "./OfficeFormDialog";
import SuccessDialog from "./SuccessDialog";
import OfficeDeleteDialog from "./OfficeDeleteDialog";
import { apiSlice } from "../../app/api/apiSlice";
import DeleteDialog from "../../sections/@dashboard/tasks/DeleteDialog";
import HistoryDialog from "../../sections/@dashboard/tasks/HistoryDialog";
import UserDialog from "../../sections/@dashboard/user/UserDialog";
import WelcomeDialog from "../../sections/@dashboard/app/WelcomeDialog";
import InitializeQMFolderStructureDialog from "../../sections/@dashboard/files/InitializeQMFolderStructure/InitializeQMFolderStructureDialog";
import QMFolderStructureDeleteDialog from "./QMFolderStructureDeleteDialog";
import RiskDialog from "../../sections/@dashboard/entries/Dialogs/RiskDialog";
import CreateKontrolleDialog from "../../sections/@dashboard/entries/Dialogs/CreateKontrolleDialog";
import CreateFBDialog from "../../sections/@dashboard/entries/Dialogs/CreateFBDialog";
import CreateSurveyDialog from "../../sections/@dashboard/survey/CreateSurveyDialog";
import UpdateRiskControlDialog from "../../sections/@dashboard/entries/Dialogs/UpdateRiskControlDialog";
import UpdateControlDialog from "../../sections/@dashboard/entries/Dialogs/UpdateControlDialog";
import FullScreenFileDialog from "./FullScreenFileDialog";
import FileDataDialog from "./FileDataDialog";
import AssignEmployeeDialog from "../../sections/@dashboard/office/AssignEmployeeDialog";
import PasswordChangeDialog from "src/sections/@dashboard/user/PasswordChangeDialog";
import AssignFileToUserDialog from "../../sections/@dashboard/files/AssignFileToUserDialog";
import DeleteUserDialog from "src/sections/@dashboard/user/DeleteUserDialog";
import AreaUserAssignDialog from "../../sections/@dashboard/office/AreaUserAssignDialog";
import ReleaseDocWithPreviewDialog from "./ReleaseDocWithPreviewDialog";
import FileDataDeleteDialog from "./FileDataDeleteDialog";
import NewsDialog from "../../sections/@dashboard/news/NewsDialog";

const SimpleDialog = ({ dialog }) => {
  const dispatch = useDispatch();

  const onClose = (refetch) => {
    if (refetch === "REFETCH") {
      console.log("dialog :>> ", dialog);
      // Refetch all after success dialog
      dispatch(apiSlice.util.resetApiState());
      // refetchOffices();
    }
    dispatch(clearDialog());
    if (dialog?.reload) {
      window.location.reload();
    }
  };

  if (dialog.content === "file-show") {
    return <FullScreenFileDialog dialog={dialog} onClose={onClose} />;
  }

  if (dialog.content === "file-release") {
    return <ReleaseDocWithPreviewDialog dialog={dialog} onClose={onClose} />;
  }

  if (dialog.content === "file-delete") {
    return (
      <FileDataDeleteDialog
        fileData={dialog.data.fileData}
        handleClose={onClose}
      />
    );
  }

  if (dialog.content === "office-delete") {
    return (
      <OfficeDeleteDialog officeData={dialog.data} handleClose={onClose} />
    );
  }

  if (dialog.content === "assign-file") {
    return <AssignFileToUserDialog dialog={dialog} handleClose={onClose} />;
  }

  if (dialog.content === "create-filedata") {
    return (
      <FileDataDialog
        dialog={dialog}
        data={dialog?.data}
        handleClose={onClose}
      />
    );
  }

  if (dialog.content === "office-form") {
    return (
      <OfficeFormDialog
        dialog={dialog}
        officeData={dialog?.data}
        handleClose={onClose}
      />
    );
  }

  if (dialog.content === "welcome") {
    return <WelcomeDialog userData={dialog?.data} handleClose={onClose} />;
  }

  if (dialog.content === "FileUpload") {
    return <FileUploadDialog handleClose={onClose} />;
  }
  if (dialog.content === "InitializeQMFolder") {
    return (
      <InitializeQMFolderStructureDialog
        dialog={dialog}
        handleClose={onClose}
      />
    );
  }

  if (dialog.content === "CREATE-SURVEY") {
    return <CreateSurveyDialog dialog={dialog} handleClose={onClose} />;
  }
  if (dialog.content === "DeleteQMFolderStructure") {
    return (
      <QMFolderStructureDeleteDialog dialog={dialog} handleClose={onClose} />
    );
  }

  if (dialog.content === "CreateTaskForm") {
    return <CreateTaskDialog handleClose={onClose} />;
  }
  if (dialog.content === "EditTaskForm") {
    return <EditTaskDialog handleClose={onClose} updatedTask={dialog.data} />;
  }
  if (dialog.content === "EventDialog") {
    return (
      <EventDialog
        handleClose={onClose}
        eventDetails={dialog?.data}
        eventFlag={dialog?.eventFlag}
      />
    );
  }
  if (dialog.content === "NewsDialog") {
    return (
      <NewsDialog
        handleClose={onClose}
        newsDetails={dialog?.data}
        newsFlag={dialog?.eventFlag}
      />
    );
  }
  if (dialog.content === "assign-employees") {
    return <AreaUserAssignDialog handleClose={onClose} dialog={dialog?.data} />;
  }
  if (dialog.content === "RiskDialog") {
    return <RiskDialog handleClose={onClose} riskDetails={dialog?.data} />;
  }
  if (dialog.content === "KontrolleDialog") {
    return <CreateKontrolleDialog handleClose={onClose} entry={dialog?.data} />;
  }
  if (dialog.content === "UpdateRiskDialog") {
    return (
      <UpdateRiskControlDialog handleClose={onClose} entry={dialog?.data} />
    );
  }
  if (dialog.content === "UpdateControlDialog") {
    return <UpdateControlDialog handleClose={onClose} entry={dialog?.data} />;
  }
  if (dialog.content === "DeleteForm") {
    return (
      <DeleteDialog
        handleClose={onClose}
        importedData={dialog?.data}
        art={dialog?.art}
        handleRemove={dialog?.handleRemove}
      />
    );
  }
  if (dialog.content === "delete-user") {
    return <DeleteUserDialog handleClose={onClose} dialog={dialog} />;
  }
  if (dialog.content === "History") {
    return (
      <HistoryDialog
        handleClose={onClose}
        eventTaskID={dialog?.data}
        art={dialog?.art}
      />
    );
  }
  if (dialog.content === "CreateFBForm") {
    return <CreateFBDialog handleClose={onClose} />;
  }
  if (dialog.content === "office-assign-employees") {
    return <AssignEmployeeDialog handleClose={onClose} office={dialog.data} />;
  }

  if (dialog.content === "create-user-form") {
    return <UserDialog onClose={onClose} dialog={dialog} />;
  }

  if (dialog?.type === "SUCCESS") {
    return <SuccessDialog dialog={dialog} handleClose={onClose} />;
  }
  if (dialog?.type === "password-change") {
    return <PasswordChangeDialog handleClose={onClose} user={dialog.user} />;
  }

  return (
    <Dialog open maxWidth="lg">
      <DialogTitle>{dialog?.title ?? ""}</DialogTitle>
      <DialogContent>
        <Typography variant="h3">{dialog?.content?.header ?? ""}</Typography>
        <Typography variant="h6">{dialog?.content?.text ?? ""}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error" variant="contained" autoFocus>
          SCHLIESSEN
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SimpleDialog.propTypes = {
  dialog: PropTypes.object.isRequired,
};

export default SimpleDialog;
