import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useAuth from "../../../../hooks/useAuth";
import { ListItemIcon, ListItemText } from "@mui/material";
import Iconify from "../../../../components/iconify";
import { checkAllowEditingDocument } from "../../../../utils/fileUtils";

function FileDataTableItemMenu({ fileData, handleEditFileData, userId }) {
  const { isAdmin } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleButtonClick = (fileData, type) => {
    handleEditFileData(fileData, type);
    handleClose();
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        color="primary"
        sx={{ ml: 1 }}
        onClick={handleClick}
      >
        BEARBEITEN
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {fileData?.status === "released" &&
          fileData?.readingConfirmation &&
          fileData?.readingConfirmationList?.includes(userId) && (
            <MenuItem
              onClick={() => handleButtonClick(fileData, "CONFIRMATION")}
            >
              LESEBESTÄTIGUNG
            </MenuItem>
          )}
        <MenuItem onClick={() => handleButtonClick(fileData, "SHOW")}>
          ANZEIGEN
        </MenuItem>
        {fileData?.status === "released" &&
          fileData?.type === "FB" &&
          fileData?.proofFolder && (
            <MenuItem onClick={() => handleButtonClick(fileData, "NACHWEIS")}>
              NACHWEIS ERZEUGEN
            </MenuItem>
          )}
        {checkAllowEditingDocument(fileData, isAdmin, userId) && (
          <MenuItem onClick={() => handleButtonClick(fileData, "EDIT")}>
            BEARBEITEN
          </MenuItem>
        )}
        {isAdmin && (
          <MenuItem onClick={() => handleButtonClick(fileData, "RELEASE")}>
            FREIGEBEN
          </MenuItem>
        )}
        {isAdmin && (
          <MenuItem onClick={() => handleButtonClick(fileData, "ASSIGN")}>
            BENUTZER ZUWEISEN
          </MenuItem>
        )}
        <MenuItem onClick={() => handleButtonClick(fileData, "DOWNLOAD")}>
          DOWNLOAD
        </MenuItem>
        {isAdmin && (
          <MenuItem onClick={() => handleButtonClick(fileData, "DELETE")}>
            <ListItemIcon>
              <Iconify
                icon="material-symbols:delete-outline"
                style={{
                  color: "red",
                }}
              />
            </ListItemIcon>
            <ListItemText>{"LÖSCHEN"}</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

FileDataTableItemMenu.propTypes = {
  handleEditFileData: PropTypes.func,
  fileData: PropTypes.object,
};

export default FileDataTableItemMenu;
