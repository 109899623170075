import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
// @mui
import { Stack, Button, Container, Typography } from "@mui/material";
// components
import { setDialog } from "../features/dialog/dialogSlice";
import Iconify from "../components/iconify";
import OfficeCardsView from "../sections/@dashboard/office/OfficeCardsView";

// ----------------------------------------------------------------------

export default function OfficePage() {
  const dispatch = useDispatch();

  const handleOpenNewOfficeDialog = () => {
    dispatch(
      setDialog({
        title: "Neue Praxis erstellen",
        type: "CREATE",
        content: "office-form",
      })
    );
  };
  return (
    <>
      <Helmet>
        <title> Mojave QM | Praxis </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Praxis
          </Typography>
          <Button
            variant="contained"
            onClick={handleOpenNewOfficeDialog}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Neue Praxis
          </Button>
        </Stack>
        <OfficeCardsView />
      </Container>
    </>
  );
}
