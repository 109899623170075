import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useDispatch } from "react-redux";
import Iconify from "src/components/iconify";
import Loading from "src/components/spinners/Loading";
import { useGetUsersQuery } from "src/features/users/usersApiSlice";
import { useUpdateEmployeesToAreaMutation } from "../../../features/offices/officesApiSlice";
import { apiSlice } from "../../../app/api/apiSlice";

function AreaUserAssignDialog({ handleClose, dialog }) {
  const { data: userList, isLoading } = useGetUsersQuery();
  const [updateEmployeesToArea] = useUpdateEmployeesToAreaMutation();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const dispatch = useDispatch();

  const { office, selectedArea } = dialog; // Zugriff auf Office und Bereich aus den Daten

  // Mitarbeiter des aktuellen Offices filtern
  const employees = office?.employees || [];

  // Filtern der Nutzer, die im Office arbeiten
  const officeUsers =
    userList?.filter((user) => employees.includes(user._id)) || [];

  // Setzt die bereits zugewiesenen Benutzer beim Laden des Dialogs
  useEffect(() => {
    if (selectedArea?.employees) {
      setSelectedUsers(selectedArea.employees);
    }
  }, [selectedArea]);

  const handleSave = async () => {
    // Mutation aufrufen, um die Mitarbeiter im Bereich zu aktualisieren
    try {
      await updateEmployeesToArea({
        officeId: office._id,
        areaId: selectedArea._id,
        employeeIds: selectedUsers,
      }).unwrap();

      console.log("Mitarbeiter erfolgreich aktualisiert:", selectedUsers);
      // Danach Dialog schließen
      handleClose();
      dispatch(apiSlice.util.resetApiState());
    } catch (error) {
      console.error("Fehler beim Aktualisieren der Mitarbeiter:", error);
    }
  };

  const handleSelectUser = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedUsers(
      typeof value === "string" ? value.split(",") : value // Für Mehrfachauswahl
    );
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Dialog open onClose={handleClose} maxWidth="sm" fullWidth>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
        }}
      >
        <Typography
          sx={{ textAlign: "center", fontSize: "30px", flex: 1 }}
          id="customized-dialog-title"
        >
          Mitarbeiter zuweisen
        </Typography>
        <Box sx={{ flex: "0 0 auto" }}>
          <Iconify
            onClick={handleClose}
            icon="mingcute:close-line"
            style={{ fontSize: "50px", color: "action.active" }}
            sx={{
              transition: "transform 0.1s",
              "&:hover": {
                transform: "scale(1.8)",
              },
            }}
          />
        </Box>
      </Box>
      <DialogContent dividers>
        <Typography variant="body1">
          Weisen Sie die Mitarbeiter dem Bereich{" "}
          <strong>{selectedArea?.name} </strong>
          der Praxis <strong>{office?.officeName}</strong> zu:
        </Typography>

        {/* Multiply User Select */}
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="multi-select-user-label">
            Mitarbeiter auswählen
          </InputLabel>
          <Select
            labelId="multi-select-user-label"
            multiple
            value={selectedUsers}
            onChange={handleSelectUser}
            renderValue={(selected) =>
              selected
                .map((userId) => {
                  const user = officeUsers.find((user) => user._id === userId);
                  return user ? `${user.firstName} ${user.lastName}` : "";
                })
                .join(", ")
            }
          >
            {officeUsers.map((user) => (
              <MenuItem key={user._id} value={user._id}>
                {user.firstName} {user.lastName} ({user.username})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          style={{ background: "#9E9E9E" }}
          variant="contained"
        >
          Abbrechen
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={selectedUsers.length === 0}
        >
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AreaUserAssignDialog;
