import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import {
  styled,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";
import { apiSlice } from "../../../../app/api/apiSlice";
import {
  useAddNewFBMutation,
  useAddControlMutation,
} from "../../../../features/entry/entryApiSlice";
import dayjs from "dayjs";
import Iconify from "../../../../components/iconify";
import MySlideSnackbar from "../../../../components/snackbar/MySlideSnackbar";
import { Step1MandatoryFields } from "../Forms/createEntryForms/Step1MandatoryFields";
import { Step2AdditionalFields } from "../Forms/createEntryForms/Step2AdditionalFields";
import { Step3Control } from "../Forms/createEntryForms/Step3Control";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

CreateFBDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default function CreateFBDialog({ handleClose }) {
  const [addNewFB] = useAddNewFBMutation();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [msg, setMsg] = useState("");

  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      incidentType: "fehler",
      title: "",
      description: "",
      recognisedOn: dayjs().format("YYYY-MM-DD"),
      classification: "leicht",
      frequency: "gering",
      measure: {
        content: "",
        date: "",
      },
      causeAnalysis: "",
      responsibleUser: "",
      kontrolle: {
        date: "1_monat",
        individuellesDatum: null,
      },
      discussionInTeamMeetingContent: false,
      anonym: false,
      scope: false,
      discussionInTeamMeetingDate: dayjs().format("YYYY-MM-DD"),
    },
  });

  const typeOfEntry = watch("incidentType");
  const meetingContent = watch("discussionInTeamMeetingContent");
  const zeitraumval = watch("kontrolle.date");
  const individuellesDatum = watch("kontrolle.individuellesDatum");

  useEffect(() => {
    reset((formValues) => ({
      ...formValues,
      frequency: typeOfEntry === "risiko" ? "gering" : "erstmalig",
    }));
  }, [typeOfEntry, reset]);

  const onSubmit = async (data) => {
    try {
      // Zuerst den Eintrag erstellen

      const kontrolle = {
        date: "",
      };

      if (data.kontrolle.date === "1_monat") {
        const today = new Date();
        const nextMonth = new Date(today);
        nextMonth.setMonth(today.getMonth() + 1);
        kontrolle.date = nextMonth;
      } else if (data.kontrolle.date === "3_monate") {
        const today = new Date();
        const nextThreeMonths = new Date(today);
        nextThreeMonths.setMonth(today.getMonth() + 3);
        kontrolle.date = nextThreeMonths;
      } else if (data.kontrolle.date === "1_jahr") {
        const today = new Date();
        const nextYear = new Date(today);
        nextYear.setFullYear(today.getFullYear() + 1);
        kontrolle.date = nextYear;
      } else if (data.kontrolle.date === "individuell") {
        kontrolle.date = new Date(data.kontrolle.individuellesDatum);
      }

      let result = await addNewFB({ ...data, kontrolle });

      handleClose("REFETCH");
      dispatch(apiSlice.util.resetApiState());
      if (result) {
        setMsg("Sie haben einen neuen Eintrag erstellt!");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby="dialog-title" open>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
        }}
      >
        <Typography
          sx={{ textAlign: "center", fontSize: "30px", flex: 1 }}
          id="dialog-title"
        >
          Neuen Eintrag erstellen
        </Typography>
        <Box sx={{ flex: "0 0 auto" }}>
          <Iconify
            onClick={handleClose}
            icon="mingcute:close-line"
            style={{ fontSize: "50px", color: "action.active" }}
            sx={{
              transition: "transform 0.1s",
              "&:hover": { transform: "scale(1.8)" },
            }}
          />
        </Box>
      </Box>

      <DialogContent dividers>
        {step === 1 && (
          <Step1MandatoryFields control={control} typeOfEntry={typeOfEntry} />
        )}
        {step === 2 && (
          <Step2AdditionalFields
            control={control}
            typeOfEntry={typeOfEntry}
            meetingContent={meetingContent}
          />
        )}
        {step === 3 && (
          <Step3Control control={control} zeitraumval={zeitraumval} />
        )}
      </DialogContent>

      <DialogActions>
        {step > 1 && (
          <Button
            onClick={() => setStep(step - 1)}
            style={{ background: "#9E9E9E" }}
            variant="contained"
          >
            Zurück
          </Button>
        )}
        {step < 3 && (
          <Button
            onClick={() => setStep(step + 1)}
            variant="contained"
            color="secondary"
            disabled={!isValid}
          >
            Weiter
          </Button>
        )}
        {step === 3 && (
          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="secondary"
            disabled={zeitraumval === "individuell" && !individuellesDatum}
          >
            Erstellen
          </Button>
        )}
      </DialogActions>
    </BootstrapDialog>
  );
}
