import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { MenuItem, TextField, Link } from "@mui/material";
import Iconify from "../iconify";
import { useSelector } from "react-redux";
import { selectCurrentUserData } from "../../features/auth/authSlice";
import { useGetUsersCleanQuery } from "../../features/users/usersApiSlice";

export default function SelectController({
  control,
  name,
  label,
  errorMsg,
  required,
  assignToMeQ = true,
  disabled,
  filterUsers,
}) {
  const currentUserData = useSelector(selectCurrentUserData);
  const { data: users } = useGetUsersCleanQuery();

  const assignToMe = (e, onChange) => {
    e.preventDefault();
    onChange(currentUserData?.id);
  };

  const createSelectValueArrays = () => {
    let a = users.map((user) => ({
      label: `${user?.firstName} ${user?.lastName}`,
      value: user?._id,
    }));
    if (filterUsers) {
      let filteredUsers = users.filter((user) =>
        Object.keys(user.roles).some((role) => filterUsers.includes(role))
      );
      a = filteredUsers.map((user) => ({
        label: `${user?.firstName} ${user?.lastName}`,
        value: user?._id,
      }));
    }
    return a.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <TextField
          select
          fullWidth
          variant="outlined"
          sx={{ minWidth: 250 }}
          label={label}
          value={value || ""}
          onChange={onChange}
          inputRef={ref}
          error={error}
          required={required}
          disabled={disabled}
          helperText={
            error ? (
              errorMsg
            ) : (
              <>
                {assignToMeQ && (
                  <div
                    onClick={(e) => assignToMe(e, onChange)} // Ruft assignToMe mit onChange auf
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      gap: "8px",
                    }}
                  >
                    <Iconify
                      icon="clarity:assign-user-line"
                      style={{ color: "blue", fontSize: "24px" }}
                    />
                    <Link
                      component="button"
                      variant="body2"
                      style={{
                        textDecoration: "none",
                        color: "blue",
                      }}
                    >
                      Mir zuweisen
                    </Link>
                  </div>
                )}
              </>
            )
          }
        >
          <MenuItem value="" disabled>
            Benutzer auswählen
          </MenuItem>
          {users && createSelectValueArrays()}
        </TextField>
      )}
    />
  );
}

SelectController.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  errorMsg: PropTypes.string,
  required: PropTypes.bool,
  assignToMeQ: PropTypes.bool,
  disabled: PropTypes.bool,
  filterUsers: PropTypes.array,
};
