import React, { useState } from "react";
import { IconButton, Badge } from "@mui/material";
import Iconify from "../../../../components/iconify";
import Chats from "./Chats";
import { useGetChatsQuery } from "src/features/chats/chatsApiSlice";
import { useSelector } from "react-redux";
import { selectCurrentUserData } from "../../../../features/auth/authSlice";

export default function UsersPopover() {
  const { data: chats } = useGetChatsQuery();
  const currentUserData = useSelector(selectCurrentUserData);
  const [open, setOpen] = useState(null);

  const totalUnreadChats = chats?.reduce((count, chat) => {
    const hasUnreadMessages = chat.messages.some(
      (msg) => !msg.read && msg.from !== currentUserData.id
    );
    return hasUnreadMessages ? count + 1 : count;
  }, 0);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnreadChats} color="error">
          <Iconify icon="majesticons:chat-2" />
        </Badge>
      </IconButton>

      {open && (
        <Chats
          open={open}
          setOpenPopover={setOpen}
          anchorEl={open}
          onClose={handleClose}
          initialChats={chats}
          currentUserId={currentUserData.id}
        />
      )}
    </>
  );
}
