import React from "react";
import PropTypes from "prop-types";
import {
  DocumentEditorContainerComponent,
  Toolbar,
} from "@syncfusion/ej2-react-documenteditor";
import { L10n } from "@syncfusion/ej2-base";
import germanLocale from "./de.json";

DocumentEditorContainerComponent.Inject(Toolbar);

// Load the German locale
L10n.load(germanLocale);

// For later use:
// https://hub.docker.com/r/syncfusion/word-processor-server
// Server to local run syncfusion service

const DocumentEditor = ({ data, documentEditorRef, file, preview }) => {
  const { action } = data;

  const loadDocument = () => {
    // Convert to blob
    const blob = new Blob([file.data], { type: file?.headers["content-type"] }); // Adjust MIME type as per your file type
    const fileReader = new FileReader(); // Set up filereader and load blob into documentEditor
    fileReader.onload = () => {
      const base64 = fileReader.result;
      if (documentEditorRef.current) {
        documentEditorRef.current.documentEditor.open(base64);
      }
    };
    fileReader.readAsDataURL(blob);
    return true;
  };

  const renderComplete = () => {
    window.onbeforeunload = function () {
      return "Want to save your changes?";
    };
    documentEditorRef.current.documentEditor.pageOutline = "#E0E0E0";
    documentEditorRef.current.documentEditor.acceptTab = true;
    documentEditorRef.current.documentEditor.resize();
    if (preview) {
      // If the document will be previewed in e.g. ReleaseDialog set zoom factor to 0.5
      documentEditorRef.current.documentEditor.zoomFactor = 0.5;
    }

    let loaded = loadDocument();

    if (loaded) {
      console.log("loaded :>> ", loaded);
    }

    // setInterval(() => {
    //   if (contentChanged) {
    //     //You can save the document as below
    //     documentEditorRef.current.documentEditor
    //       .saveAsBlob("Docx")
    //       .then((blob) => {
    //         let exportedDocument = blob;
    //         //Now, save the document where ever you want.
    //         console.log("AUTOSAVE");
    //       });
    //     setContentChanged(false);
    //   }
    // }, 10000);
    // // SAVES DOC EVERY 10 SECS
    // documentEditorRef.current.contentChange = () => {
    //   setContentChanged(true);
    // };
    console.log("render");
  };

  return (
    <DocumentEditorContainerComponent
      id="documentEditorRef"
      locale="de"
      created={renderComplete}
      height="100%"
      enableToolbar={!["SHOW", "CONFIRMATION"].includes(action)}
      ref={documentEditorRef}
      serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
      restrictEditing={["SHOW", "CONFIRMATION"].includes(action)}
      layoutType={"Pages"} // If layoutType is Continuous the format of the doc will be disrupted when saving
    ></DocumentEditorContainerComponent>
  );
};

DocumentEditor.propTypes = {
  data: PropTypes.shape({
    fileData: PropTypes.object.isRequired,
    action: PropTypes.string,
  }).isRequired,
};

export default DocumentEditor;
