import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import Iconify from "../../../../components/iconify";
import { useAddControlMutation } from "../../../../features/entry/entryApiSlice";
import MySlideSnackbar from "../../../../components/snackbar/MySlideSnackbar";
import FormControl from "../Forms/FormControl";

export default function CreateKontrolleDialog({ handleClose, entry }) {
  const [addControl] = useAddControlMutation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      date: "1_monat",
    },
  });
  const zeitraumval = watch("date");

  const individuellesDatum = watch("individuellesDatum");

  const onSubmit = async (data) => {
    try {
      let kontrolle = {
        FBMEntryID: entry._id,
        date: "",
      };

      if (data.date === "1_monat") {
        const today = new Date();
        const nextMonth = new Date(today);
        nextMonth.setMonth(today.getMonth() + 1);
        kontrolle.date = nextMonth;
      } else if (data.date === "3_monate") {
        const today = new Date();
        const nextThreeMonths = new Date(today);
        nextThreeMonths.setMonth(today.getMonth() + 3);
        kontrolle.date = nextThreeMonths;
      } else if (data.date === "1_jahr") {
        const today = new Date();
        const nextYear = new Date(today);
        nextYear.setFullYear(today.getFullYear() + 1);
        kontrolle.date = nextYear;
      } else if (data.date === "individuell") {
        kontrolle.date = new Date(data?.individuellesDatum);
      }

      const result = await addControl({ ...kontrolle });
      if (result) {
        setMsg("Sie haben erfolgreich eine neue Kontrolle hinzugefügt.");
        setSnackbarOpen(true);
        handleClose("REFETCH");
      }
    } catch (error) {
      console.log("Fehler beim Hinzufügen der Kontrolle:", error);
    }
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open
        fullWidth
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Typography
            sx={{ textAlign: "center", fontSize: "30px", flex: 1 }}
            id="customized-dialog-title"
          >
            Kontrolle
          </Typography>
          <Box sx={{ flex: "0 0 auto" }}>
            <Iconify
              onClick={handleClose}
              icon="mingcute:close-line"
              style={{ fontSize: "50px", color: "action.active" }}
              sx={{
                transition: "transform 0.1s",
                "&:hover": {
                  transform: "scale(1.8)",
                },
              }}
            />
          </Box>
        </Box>
        <Divider />
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl control={control} zeitraumval={zeitraumval} />
          </form>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ background: "#9E9E9E" }}
            variant="contained"
          >
            Abbrechen
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            type="submit"
            variant="contained"
            color="secondary"
            disabled={zeitraumval === "individuell" && !individuellesDatum}
          >
            Speichern
          </Button>
        </DialogActions>
      </Dialog>

      <MySlideSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={msg}
      />
    </div>
  );
}
