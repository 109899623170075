export const combineDateAndTime = (date, time) => {
  return `${date}T${time}`;
};

export const formatDateTime = (dateTime, format) => {
  const date = new Date(dateTime);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  if (format === "date") {
    return `${year}-${month}-${day}`;
  } else if (format === "time") {
    return `${hours}:${minutes}`;
  } else {
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
};
