import { Helmet } from "react-helmet-async";
// @mui
import { Grid, Button, Container, Stack, Typography } from "@mui/material";
// components
import Iconify from "../components/iconify";
import {
  BlogPostCard,
  BlogPostsSort,
  BlogPostsSearch,
} from "../sections/@dashboard/blog";
// mock
import POSTS from "../_mock/blog";
import LawAccordion from "../sections/@dashboard/laws/LawAccordion";

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: "latest", label: "Latest" },
  { value: "popular", label: "Popular" },
  { value: "oldest", label: "Oldest" },
];

// ----------------------------------------------------------------------

export default function LawsPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Gesetzestexte | Mojave Med QM </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Gesetzesübersicht
          </Typography>
          <Button
            disabled
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Neues Gesetz einfügen
          </Button>
        </Stack>

        <LawAccordion />
      </Container>
    </>
  );
}
