import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
// @mui
import { Stack, Button, Container, Typography } from "@mui/material";
// components
import { setDialog } from "../features/dialog/dialogSlice";
import Iconify from "../components/iconify";
import NewUserListTable from "../sections/@dashboard/user/NewUserListTable";

// ----------------------------------------------------------------------

export default function UserPage() {
  const dispatch = useDispatch();

  const handleOpenNewUserDialog = () => {
    dispatch(
      setDialog({
        title: "Neuen Benutzer erstellen",
        type: "CREATE",
        content: "create-user-form",
      })
    );
  };
  return (
    <>
      <Helmet>
        <title> Mojave QM | Benutzer </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Benutzer
          </Typography>
          <Button
            variant="contained"
            onClick={handleOpenNewUserDialog}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Neuer Benutzer
          </Button>
        </Stack>
        <NewUserListTable />
      </Container>
    </>
  );
}
