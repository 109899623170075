import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setDialog } from "src/features/dialog/dialogSlice";
import Iconify from "src/components/iconify";
import Loading from "src/components/spinners/Loading";
import {
  useDeleteUserMutation,
  useGetUsersQuery,
} from "src/features/users/usersApiSlice";

function DeleteUserDialog({ handleClose, dialog }) {
  const { data: userList, isLoading } = useGetUsersQuery();
  const [deleteUser] = useDeleteUserMutation();
  const [selectedUser, setSelectedUser] = useState("");
  const dispatch = useDispatch();

  if (isLoading) {
    return <Loading />;
  }

  console.log("dialog", dialog);

  // Filter the userList to exclude the user being deleted
  const filteredUserList = userList.filter(
    (user) => user._id !== dialog?.data._id
  );

  const handleDeleteUser = async () => {
    try {
      console.log("userId", dialog?.data._id);
      console.log("newOwnerId", selectedUser);
      const response = await deleteUser({
        userId: dialog?.data._id,
        newOwnerId: selectedUser,
      }).unwrap();
      dispatch(
        setDialog({
          title: "Benutzer wurde erfolgreich gelöscht!",
          type: "SUCCESS",
          data: {
            successText:
              "Der Benutzer und seine Daten wurden erfolgreich übertragen!",
          },
        })
      );
    } catch (error) {
      console.log("Error deleting user:", error);
      dispatch(
        setDialog({
          title: "Fehler beim Löschen",
          type: "ERROR",
          data: {
            errorText: "Es gab ein Problem beim Löschen des Benutzers.",
          },
        })
      );
    } finally {
      handleClose("REFETCH");
    }
  };

  return (
    <Dialog open handleClose={handleClose} maxWidth="sm" fullWidth>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
        }}
      >
        <Typography
          sx={{ textAlign: "center", fontSize: "30px", flex: 1 }}
          id="customized-dialog-title"
        >
          Benutzer löschen
        </Typography>
        <Box sx={{ flex: "0 0 auto" }}>
          <Iconify
            onClick={handleClose}
            icon="mingcute:close-line"
            style={{ fontSize: "50px", color: "action.active" }}
            sx={{
              transition: "transform 0.1s", // Smooth transition for transform
              "&:hover": {
                transform: "scale(1.8)", // Scale up the icon when hovered
              },
            }}
          />
        </Box>
      </Box>
      <DialogContent dividers>
        <Typography variant="body1">
          Wählen Sie einen Benutzer, an den die Daten des zu löschenden
          Benutzers übertragen werden sollen:
        </Typography>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="select-user-label">Benutzer auswählen</InputLabel>
          <Select
            labelId="select-user-label"
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
            label="Benutzer auswählen"
          >
            {filteredUserList.map((user) => (
              <MenuItem key={user._id} value={user._id}>
                {user.firstName} {user.lastName} ({user.username})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          style={{ background: "#9E9E9E" }}
          variant="contained"
        >
          Abbrechen
        </Button>
        <Button
          onClick={handleDeleteUser}
          color="secondary"
          variant="contained"
          disabled={!selectedUser}
          style={{ textTransform: "none" }}
        >
          Löschen und übertragen
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteUserDialog;
