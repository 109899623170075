import React, { useState } from "react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Breadcrumbs,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemButton,
} from "@mui/material";
import { Icon } from "@iconify/react";

const FolderSelector = ({
  folderStructure,
  control,
  name,
  required,
  infoText,
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required: required && "Bitte einen Ordner auswählen.",
    },
  });
  const [selectedPath, setSelectedPath] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");

  const handleFolderClick = (folder) => {
    const newPath = [...selectedPath, folder];
    setSelectedPath(newPath);
    field.onChange(newPath);
  };

  const handleBackClick = (index) => {
    const newSelectedPath = selectedPath.slice(0, index + 1);
    setSelectedPath(newSelectedPath);
    field.onChange(newSelectedPath);
  };

  const handleAddFolder = () => {
    const newFolder = {
      id: `${selectedPath[selectedPath.length - 1].id}\\${newFolderName}`,
      name: newFolderName,
      type: "folder",
      children: [],
    };

    setNewFolderName("");
    setOpenDialog(false);
    setSelectedPath([...selectedPath, newFolder]);
    field.onChange([...selectedPath, newFolder]);
  };

  const currentLevel = selectedPath.length
    ? selectedPath[selectedPath.length - 1].children
    : folderStructure;

  return (
    <Box sx={{ minHeight: 125 }}>
      <div style={{ minHeight: 26 }}>
        {selectedPath.length > 0 ? (
          <Breadcrumbs separator=">">
            {selectedPath.map((folder, index) => (
              <Typography
                key={folder.id}
                color={
                  index === selectedPath.length - 1 ? "textPrimary" : "inherit"
                }
                onClick={() => handleBackClick(index)}
                style={{
                  cursor:
                    index === selectedPath.length - 1 ? "default" : "pointer",
                }}
              >
                <Icon icon="material-symbols:folder-outline" />
                {folder.name}
              </Typography>
            ))}
          </Breadcrumbs>
        ) : (
          <Typography
            variant="body1"
            sx={error && { color: "red", fontWeight: "bold" }}
          >
            {infoText ?? "Bitte einen Ordner auswählen."}
          </Typography>
        )}
      </div>
      <div style={{ minHeight: 85 }}>
        <List>
          {currentLevel.map(
            (item) =>
              item.type === "folder" && (
                <ListItem
                  button
                  key={item.id}
                  onClick={() => handleFolderClick(item)}
                >
                  <ListItemIcon>
                    <Icon icon="material-symbols:folder-outline" />
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItem>
              )
          )}
          {selectedPath?.length > 0 && (
            <ListItemButton onClick={() => setOpenDialog(true)}>
              <ListItemIcon>
                <Icon icon="material-symbols:add-circle-outline" />
              </ListItemIcon>
              <ListItemText primary="Neuen Ordner erstellen" />
            </ListItemButton>
          )}
        </List>
      </div>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Neuen Unterordner hinzufügen</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Ordnername"
            fullWidth
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            variant="outlined"
            color="error"
          >
            Abbrechen
          </Button>
          <Button onClick={handleAddFolder} variant="contained" color="primary">
            HINZUFÜGEN
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

FolderSelector.propTypes = {
  folderStructure: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      children: PropTypes.array,
    })
  ).isRequired,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

export default FolderSelector;
