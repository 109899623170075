import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import Scrollbar from "../../../../components/scrollbar/Scrollbar";
import MultiSelectUsersController from "../../../../components/forms/MultiSelectUsersController";

import { Icon } from "@iconify/react";
import {
  FILTER_STATUS_OPTIONS,
  FILTER_RATING_OPTIONS,
  FILTER_PRICE_OPTIONS,
} from "./fileDataTableUtils";
import { FormProvider, useForm } from "react-hook-form";

FileDataTableFilterSidebar.propTypes = {
  openFilter: PropTypes.bool,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
};

export default function FileDataTableFilterSidebar({
  openFilter,
  onOpenFilter,
  onCloseFilter,
  activeFilters,
}) {
  // Initialize a single state object for all filters
  const [filters, setFilters] = useState({
    ...activeFilters,
  });
  const [filtersActive, setFiltersActive] = useState(false);

  const { handleSubmit, control, watch, getValues } = useForm({
    defaultValues: {},
  });

  const handleActiveFilters = (active) => {
    setFiltersActive(active);
  };

  // General handler for checkbox changes
  const handleCheckboxChange = (event, type) => {
    const { value, checked } = event.target;
    setFilters((prev) => ({
      ...prev,
      [type]: checked
        ? [...prev[type], value]
        : prev[type].filter((option) => option !== value),
    }));
  };

  // General handler for radio changes
  const handleRadioChange = (event, type) => {
    setFilters((prev) => ({ ...prev, [type]: event.target.value }));
  };

  // Handler to clear all filters
  const handleClearAll = () => {
    let defaultFilters = {
      status: ["new", "edit", "revision", "released"],
      category: "All",
      colors: [],
      price: "",
      rating: "",
    };
    setFilters(defaultFilters);
    handleActiveFilters(false);
    onCloseFilter(defaultFilters);
  };

  const onSubmit = (data) => {
    console.log("data :>> ", data);
  };

  const onClose = () => {
    handleSubmit(onSubmit)();
    onCloseFilter(filters);
    handleActiveFilters(true);
  };

  return (
    <>
      <Button
        disableRipple
        color={filtersActive ? "warning" : "inherit"}
        variant={filtersActive ? "contained" : "outlined"}
        sx={{ ml: 1 }}
        startIcon={<Icon icon="mdi:filter-cog" />}
        onClick={onOpenFilter}
      >
        FILTER&nbsp;
      </Button>

      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onClose}
        PaperProps={{
          sx: { width: 280, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            FILTER
          </Typography>
          <IconButton onClick={onClose}>
            <Icon icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <FormProvider {...{ handleSubmit, control, watch }}>
            <Stack spacing={3} sx={{ p: 3 }}>
              <div>
                <Typography variant="subtitle1" gutterBottom>
                  Dokumente mit Status anzeigen:
                </Typography>
                <FormGroup>
                  {FILTER_STATUS_OPTIONS.map((item) => (
                    <FormControlLabel
                      key={item.value}
                      control={
                        <Checkbox
                          value={item.value}
                          checked={filters.status.includes(item.value)}
                          onChange={(event) =>
                            handleCheckboxChange(event, "status")
                          }
                        />
                      }
                      label={item.label}
                    />
                  ))}
                </FormGroup>
              </div>

              <div>
                <Typography variant="subtitle1" gutterBottom>
                  Verantwortlich:
                </Typography>
                <MultiSelectUsersController
                  control={control}
                  name="user"
                  label="Mitarbeiter"
                  fullWidth
                />
              </div>

              <div>
                <Typography variant="subtitle1" gutterBottom>
                  Price
                </Typography>
                <RadioGroup
                  value={filters.price}
                  onChange={(event) => handleRadioChange(event, "price")}
                >
                  {FILTER_PRICE_OPTIONS.map((item) => (
                    <FormControlLabel
                      key={item.value}
                      value={item.value}
                      control={<Radio />}
                      label={item.label}
                    />
                  ))}
                </RadioGroup>
              </div>

              <div>
                <Typography variant="subtitle1" gutterBottom>
                  Rating
                </Typography>
                <RadioGroup
                  value={filters.rating}
                  onChange={(event) => handleRadioChange(event, "rating")}
                >
                  {FILTER_RATING_OPTIONS.map((item, index) => (
                    <FormControlLabel
                      key={item}
                      value={item}
                      control={
                        <Radio
                          disableRipple
                          color="default"
                          icon={<Rating readOnly value={4 - index} />}
                          checkedIcon={<Rating readOnly value={4 - index} />}
                          sx={{
                            "&:hover": { bgcolor: "transparent" },
                          }}
                        />
                      }
                      label="& Up"
                      sx={{
                        my: 0.5,
                        borderRadius: 1,
                        "&:hover": { opacity: 0.48 },
                      }}
                    />
                  ))}
                </RadioGroup>
              </div>
            </Stack>
          </FormProvider>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          <Button
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            sx={{ mb: 1 }}
            startIcon={<Icon icon="ic:round-clear-all" />}
            onClick={onClose}
          >
            FILTER ANWENDEN
          </Button>
          <Button
            fullWidth
            size="large"
            color="inherit"
            variant="outlined"
            startIcon={<Icon icon="ic:round-clear-all" />}
            onClick={handleClearAll}
          >
            FILTER LÖSCHEN
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
