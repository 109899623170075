import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Divider,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import ControlledSelectField from "../../../../components/forms/ControlledSelectField";
import Iconify from "../../../../components/iconify";
import MySlideSnackbar from "../../../../components/snackbar/MySlideSnackbar";
import FormControl from "../Forms/FormControl";
import dayjs from "dayjs";
import { useUpdateRiskControlMutation } from "../../../../features/entry/entryApiSlice";

export default function UpdateRiskControlDialog({ handleClose, entry }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [newControl, setNewControl] = useState(false);
  const [updateRiskControl] = useUpdateRiskControlMutation();

  const { handleSubmit, control, watch, setError, clearErrors } = useForm({
    defaultValues: {
      status: "relevant",
      classification: "schwer",
      frequency: "hoch",
      date: "1_monat",
      individuellesDatum: dayjs(),
    },
  });

  const selectedStatus = watch("status");
  const zeitraumval = watch("date");
  const individuellesDatum = watch("individuellesDatum");

  const onSubmit = async (data) => {
    let kontrolleData = null;

    if (selectedStatus === "relevant") {
      kontrolleData = {
        date: "",
      };

      if (data.date === "1_monat") {
        const today = new Date();
        const nextMonth = new Date(today);
        nextMonth.setMonth(today.getMonth() + 1);
        kontrolleData.date = nextMonth;
      } else if (data.date === "3_monate") {
        const today = new Date();
        const nextThreeMonths = new Date(today);
        nextThreeMonths.setMonth(today.getMonth() + 3);
        kontrolleData.date = nextThreeMonths;
      } else if (data.date === "1_jahr") {
        const today = new Date();
        const nextYear = new Date(today);
        nextYear.setFullYear(today.getFullYear() + 1);
        kontrolleData.date = nextYear;
      } else if (data.date === "individuell") {
        if (!data.individuellesDatum) {
          setError("individuellesDatum", {
            type: "manual",
            message: "Bitte wählen Sie ein Datum aus.",
          });
          return;
        } else {
          clearErrors("individuellesDatum");
          kontrolleData.date = new Date(data.individuellesDatum);
        }
      }
    }

    const dataToUpdate = {
      entryID: entry?.entryID,
      controlID: entry?.controlID,
      status: data.status,
      classification: data.classification,
      frequency: data.frequency,
      kontrolleDataNew: kontrolleData,
    };

    const result = await updateRiskControl(dataToUpdate);
    if (result) {
      handleClose("REFETCH");
      setMsg("Daten erfolgreich aktualisiert!");
      setSnackbarOpen(true);
    }
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open
        maxWidth="sm"
        fullWidth
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Typography
            sx={{ textAlign: "center", fontSize: "30px", flex: 1 }}
            id="customized-dialog-title"
          >
            Risiko kontrollieren
          </Typography>
          <Box sx={{ flex: "0 0 auto" }}>
            <Iconify
              onClick={handleClose}
              icon="mingcute:close-line"
              style={{ fontSize: "50px", color: "action.active" }}
              sx={{
                transition: "transform 0.1s",
                "&:hover": {
                  transform: "scale(1.8)",
                },
              }}
            />
          </Box>
        </Box>

        <Divider />
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    textAlign: "left",
                    fontSize: "15px",
                    flex: 1,
                    mb: 2,
                  }}
                  id="customized-dialog-title"
                >
                  Soll der Status des Risikos angepasst werden?
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <ControlledSelectField
                  control={control}
                  name="status"
                  label="Status"
                  required
                  itemArray={[
                    { label: "relevant", value: "relevant" },
                    { label: "nicht relevant", value: "notRelevant" },
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    textAlign: "left",
                    fontSize: "15px",
                    flex: 1,
                    mb: 2,
                    mt: 1,
                  }}
                  id="customized-dialog-title"
                >
                  Ändern Sie die aktuelle Einstufung &
                  Eintrittswahrscheinlichkeit.
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <ControlledSelectField
                  control={control}
                  name="classification"
                  label="Einstufung"
                  required
                  itemArray={[
                    { label: "leicht", value: "leicht" },
                    { label: "mittel", value: "mittel" },
                    { label: "schwer", value: "schwer" },
                  ]}
                />
              </Grid>
              <Grid item xs={6}>
                <ControlledSelectField
                  control={control}
                  name="frequency"
                  label="Eintrittswahrscheinlichkeit"
                  required
                  itemArray={[
                    { label: "gering", value: "gering" },
                    { label: "mittel", value: "mittel" },
                    { label: "hoch", value: "hoch" },
                  ]}
                />
              </Grid>
            </Grid>

            {selectedStatus === "relevant" && (
              <Box sx={{ mt: 3 }}>
                <FormControl control={control} zeitraumval={zeitraumval} />
              </Box>
            )}
          </form>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            color="secondary"
          >
            Absenden
          </Button>
        </DialogActions>
      </Dialog>

      <MySlideSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={msg}
      />
    </div>
  );
}
