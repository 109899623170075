import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import NewUserForm from "./NewUserForm";
import { useRegisterMutation } from "src/features/auth/registerApiSlice";
import { useUpdateUserMutation } from "src/features/users/usersApiSlice";
import { useDispatch } from "react-redux";
import { setDialog } from "src/features/dialog/dialogSlice";
import Iconify from "src/components/iconify";

function UserDialog({ onClose, dialog }) {
  console.log("dialog :>> ", dialog.data);

  const [register, { isSuccess: isRegisterSuccess }] = useRegisterMutation();
  const [updateUser, { isSuccess: isUpdateSuccess }] = useUpdateUserMutation();
  const dispatch = useDispatch();

  const handleRegister = async (data) => {
    try {
      const userData = await register(data).unwrap();
      console.log("userData :>> ", userData);
      if (isRegisterSuccess) {
        dispatch(
          setDialog({
            title: "Neuer Benutzer wurde erfolgreich erstellt!",
            type: "SUCCESS",
            data: {
              successText: "Neuer Benutzer wurde erfolgreich erstellt!",
            },
          })
        );
      }
    } catch (error) {
      console.log("error :>> ", error);
      if (error.data.message === "Benutzername ist bereits in Benutzung.") {
        throw new Error("Benutzername ist bereits in Benutzung.");
      }
      if (error.data.message === "E-Mail Adresse ist bereits in Benutzung.") {
        throw new Error("E-Mail Adresse ist bereits in Benutzung.");
      }
    } finally {
      onClose("REFETCH");
    }
  };

  const handleUserUpdate = async (data) => {
    try {
      const updatedData = { ...data, id: data._id };
      delete updatedData._id;
      const userData = await updateUser(updatedData).unwrap();
      console.log("userData :>> ", userData);
      if (isUpdateSuccess) {
        console.log("data", data);
        dispatch(
          setDialog({
            title: "Benutzer wurde erfolgreich aktualisiert!",
            type: "SUCCESS",
            data: {
              successText: "Benutzer wurde erfolgreich aktualisiert!",
            },
          })
        );
      }
    } catch (error) {
      console.log("error :>> ", error);
      if (error.data.message === "Benutzername ist bereits in Benutzung.") {
        throw new Error("Benutzername ist bereits in Benutzung.");
      }
      if (error.data.message === "E-Mail Adresse ist bereits in Benutzung.") {
        throw new Error("E-Mail Adresse ist bereits in Benutzung.");
      }
    } finally {
      onClose("REFETCH");
    }
  };

  const onSubmit = async (data) => {
    delete data.checkPassword;
    if (dialog?.type === "CREATE") {
      await handleRegister(data);
    } else {
      await handleUserUpdate(data);
    }
    onClose();
  };

  return (
    <div>
      <Dialog open maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            p: 2,
          }}
        >
          <Typography
            sx={{ textAlign: "center", fontSize: "30px", flex: 1 }}
            id="customized-dialog-title"
          >
            {dialog?.title ?? ""}
          </Typography>
          <Box sx={{ flex: "0 0 auto" }}>
            <Iconify
              onClick={onClose}
              icon="mingcute:close-line"
              style={{ fontSize: "50px", color: "action.active" }}
              sx={{
                transition: "transform 0.1s", // Smooth transition for transform
                "&:hover": {
                  transform: "scale(1.8)", // Scale up the icon when hovered
                },
              }}
            />
          </Box>
        </Box>
        <DialogContent dividers>
          <NewUserForm
            initialValues={dialog?.data ?? ""}
            onSubmit={onSubmit}
            dialogType={dialog?.type}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            style={{ background: "#9E9E9E" }}
            variant="contained"
          >
            Abbrechen
          </Button>
          <Button
            form="user-form"
            type="submit"
            color="secondary"
            variant="contained"
            autoFocus
          >
            {dialog?.type === "CREATE" ? "Erstellen" : "Speichern"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

UserDialog.propTypes = {};

export default UserDialog;
