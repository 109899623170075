import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import FileDataWorkflowForm from "../../../../components/forms/Forms/FileDataWorkflowForm";

const fileTypeRegEx = /(?:\.([^.]+))?$/;

const defaultFormData = {
  fileName: "",
  extension: "",
  version: "1.1",
  status: "new",
  comment: "Testkommentar",
  recall: false,
  recallPeriod: "24.01.2025",
  recallDate: "31.12.2026",
  released: false,
};

function WorkFlowStep({ data, onSubmitDetails }) {
  // Present workflow matching documenttyp
  console.log("data :>> ", data);
  let todo = "Read data from file";
  const { handleSubmit, control, setError } = useForm({
    defaultValues: {
      ...defaultFormData,
      extension: fileTypeRegEx.exec(data?.file?.name)[1],
      fileName: data?.file?.name.split(".").slice(0, -1).join("."),
    },
  });

  const onSubmit = (formData) => {
    console.log("formData :>> ", formData);
    onSubmitDetails(formData);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <FileDataWorkflowForm control={control} />

        <div style={{ margin: 20 }}>
          <Button
            sx={{ mt: 3, mb: 3 }}
            type="submit"
            fullWidth
            color="secondary"
            variant="contained"
            autoFocus
          >
            Bestätigen
          </Button>
        </div>
      </form>
    </div>
  );
}

WorkFlowStep.propTypes = { data: PropTypes.object };

export default WorkFlowStep;
