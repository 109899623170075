import { apiSlice } from "../../app/api/apiSlice";

export const newsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNews: builder.query({
      query: () => ({
        url: "/news",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),
      // eslint-disable-next-line
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "News", id: "LIST" },
            ...result.ids.map((id) => ({ type: "News", id })),
          ];
        }
        return [{ type: "News", id: "LIST" }];
      },
    }),
    getNewsById: builder.query({
      query: (newsId) => ({
        url: `/news/${newsId}`,
        method: "GET",
      }),
      providesTags: (result, error, arg) => [{ type: "News", id: arg }],
    }),

    addNewNews: builder.mutation({
      query: (news) => ({
        url: "/news",
        method: "POST",
        body: {
          ...news,
        },
      }),
      invalidatesTags: [{ type: "News", id: "LIST" }],
    }),
    updateNews: builder.mutation({
      query: (newsUpdate) => ({
        url: "/news",
        method: "PATCH",
        body: {
          ...newsUpdate,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "News", id: arg.id }],
    }),
    likeNews: builder.mutation({
      query: (id) => ({
        url: `/news/${id}/like`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "News", id: arg }],
    }),
    viewNews: builder.mutation({
      query: (id) => ({
        url: `/news/${id}/view`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "News", id: arg }],
    }),
    deleteNews: builder.mutation({
      query: (id) => ({
        url: `/news`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "News", id: arg.id }],
    }),
  }),
});

export const {
  useGetNewsQuery,
  useGetNewsByIdQuery,
  useAddNewNewsMutation,
  useUpdateNewsMutation,
  useLikeNewsMutation,
  useViewNewsMutation,
  useDeleteNewsMutation,
} = newsApiSlice;
