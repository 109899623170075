import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

const faqs = [
  {
    question: "Wie kann ich mein Passwort ändern?",
    answer:
      "Um Ihr Passwort zu ändern, gehen Sie zu den Einstellungen und klicken Sie auf 'Passwort ändern'.",
  },
  {
    question: "Wie kann ich Benachrichtigungen verwalten?",
    answer:
      "Gehen Sie zu den Einstellungen und klicken Sie auf 'Benachrichtigungseinstellungen', um Ihre Benachrichtigungen zu verwalten.",
  },
  {
    question: "Wie füge ich ein Unternehmenslogo hinzu?",
    answer:
      "Gehen Sie zu den Unternehmensbezogenen Einstellungen und laden Sie Ihr Logo im entsprechenden Bereich hoch.",
  },
  {
    question: "Was soll ich tun, wenn ich mein Passwort vergessen habe?",
    answer:
      "Klicken Sie auf der Anmeldeseite auf 'Passwort vergessen' und folgen Sie den Anweisungen zur Wiederherstellung Ihres Passworts.",
  },
  {
    question: "Wie kann ich meinen Account löschen?",
    answer:
      "Bitte kontaktieren Sie den Support, um Ihren Account löschen zu lassen.",
  },
  {
    question: "Wie kann ich meine Kontaktdaten aktualisieren?",
    answer:
      "Gehen Sie zu den Profileinstellungen und aktualisieren Sie Ihre Kontaktdaten dort.",
  },
];

const FAQSettings = () => {
  return (
    <Box sx={{ p: 1 }}>
      <Typography variant="h4" gutterBottom>
        Häufig gestellte Fragen:
      </Typography>
      <List>
        {faqs.map((faq, index) => (
          <React.Fragment key={index}>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={<Typography variant="h6">{faq.question}</Typography>}
                secondary={
                  <Typography variant="body1">{faq.answer}</Typography>
                }
              />
            </ListItem>
            {index < faqs.length - 1 && <Divider component="li" />}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default FAQSettings;
