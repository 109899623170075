import React, { useState, useCallback } from "react";
import { Typography, Divider, Button, TextField, Box } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useUpdateTenantLogoMutation } from "../../../features/tenants/tenantsApiSlice";
import { uploadTenantLogoAxios } from "../../../app/api/axios/services/files.service";
import { useDispatch, useSelector } from "react-redux";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { serverURL } from "../../../app/api/axios/http.service";
import { selectCurrentUserData } from "../../../features/auth/authSlice";

export default function TenantSettings() {
  const [logo, setLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [website, setWebsite] = useState("");
  const [updateTenantLogo] = useUpdateTenantLogoMutation();
  const currentUserData = useSelector(selectCurrentUserData);
  const dispatch = useDispatch();

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setLogo(file);
    setLogoPreview(URL.createObjectURL(file));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleSaveSettings = () => {
    // Add save logic for company settings here
    console.log("Settings saved: ", { logo, website });
  };

  const handleChangeTenantLogo = async () => {
    console.log("logo :>> ", logo.name);
    try {
      const result = await updateTenantLogo({ logoName: logo.name }).unwrap();
      if (result && result.type === "success") {
        const formData = new FormData();
        formData.append("file", logo);
        const uploadResult = await uploadTenantLogoAxios(formData);
        if (uploadResult && uploadResult.data.status === "success") {
          dispatch(
            setDialog({
              title: "Logo geändert!",
              type: "SUCCESS",
              reload: true,
              data: {
                successText: "Das Logo der Praxis wurde erfolgreich geändert!",
              },
            })
          );
        }
        console.log("uploadResult :>> ", uploadResult);
      }
      console.log("result :>> ", result);
    } catch (error) {
      console.log("error :>> ", error);
    }
    // change logo url in tenant
    // upload file with response
  };

  return (
    <>
      <Divider sx={{ mb: 3 }} />
      <Typography variant="h5" sx={{ mb: 2 }}>
        Aktuelles Logo:
      </Typography>
      <Box
        component="img"
        // src={PraxisSVG}
        src={`${serverURL()}/public/tenant/image/${
          currentUserData?.tenant ?? " "
        }`}
        alt="Praxis Logo"
        // onClick={() =>
        //   window.open("https://zahnarztpraxis-oesterle.de/", "_blank")
        // }
        // href="https://zahnarztpraxis-oesterle.de/" make this go to tenant homepage
        sx={{
          cursor: "pointer",
        }}
      />
      <Typography variant="body1" sx={{ mb: 2 }}>
        Wenn Sie ein neues Logo hochladen möchten, ziehen Sie das Bild in den
        Bereich oder klicken Sie darauf.
      </Typography>
      <Box
        {...getRootProps()}
        sx={{
          border: "2px dashed #000000",
          borderRadius: "8px",
          padding: "20px",
          textAlign: "center",
          cursor: "pointer",
          mb: 3,
          color: "#000000",
          fontWeight: "bold",
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography
            variant="body1"
            sx={{ color: "black", fontWeight: "bold" }}
          >
            Ziehe das Bild hierher...
          </Typography>
        ) : (
          <Typography
            variant="body1"
            sx={{ color: "black", fontWeight: "bold" }}
          >
            Hier klicken oder Bild hierherziehen
          </Typography>
        )}
      </Box>
      <Typography variant="h6" sx={{ color: "black", fontWeight: "bold" }}>
        Vorschau:
      </Typography>
      {logo ? (
        <>
          <Typography variant="body2" component="p" sx={{ mb: 2 }}>
            {logo.name}
          </Typography>
          {logoPreview && (
            <>
              <Box mt={2} sx={{ textAlign: "center" }}>
                <img
                  src={logoPreview}
                  alt="Logo Preview"
                  style={{ maxWidth: "100%", maxHeight: 200 }}
                />
              </Box>
              <Button
                sx={{ m: 2, minWidth: 250 }}
                variant="contained"
                color="primary"
                onClick={handleChangeTenantLogo}
              >
                LOGO HOCHLADEN
              </Button>
            </>
          )}
        </>
      ) : (
        <Typography variant="body2" component="p" sx={{ mb: 2 }}>
          Noch kein Logo ausgewählt. Bitte fügen Sie das Logo über die oben
          aufgeführte Funktion hinzu.
        </Typography>
      )}
      <TextField
        label="Website des Unternehmens"
        variant="outlined"
        value={website}
        onChange={handleWebsiteChange}
        fullWidth
        sx={{ mt: 3, mb: 3 }}
      />
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSaveSettings}
        >
          Einstellungen speichern
        </Button>
      </Box>
    </>
  );
}
