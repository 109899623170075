import { apiSlice } from "../../app/api/apiSlice";

export const chatsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChats: builder.query({
      query: () => ({
        url: "/chats",
        validateStatus: (response, result) =>
          response.status === 200 && !result.isError,
      }),

      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Chat", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Chat", id })),
          ];
        }
        return [{ type: "Chat", id: "LIST" }];
      },
    }),

    createNewChat: builder.mutation({
      query: (data) => ({
        url: `/chats`,
        method: "POST",
        body: {
          ...data,
        },
      }),
      invalidatesTags: [{ type: "Chat", id: "LIST" }],
    }),

    sendMessage: builder.mutation({
      query: (chat) => ({
        url: `/chats/message`,
        method: "POST",
        body: {
          ...chat,
        },
      }),
      invalidatesTags: [{ type: "Chat", id: "LIST" }],
    }),
    markMessagesAsRead: builder.mutation({
      query: (chatId) => ({
        url: `/chats/${chatId}/read`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Chat", id: arg.id }],
    }),
  }),
});

export const {
  useGetChatsQuery,
  useCreateNewChatMutation,
  useSendMessageMutation,
  useMarkMessagesAsReadMutation,
} = chatsApiSlice;
