import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm, FormProvider } from "react-hook-form";
import Button from "@mui/material/Button";
import {
  styled,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";
import NewsForm from "./NewsForm";
import {
  useAddNewNewsMutation,
  useUpdateNewsMutation,
} from "../../../features/news/newsApiSlice";
import Iconify from "../../../components/iconify";
import { MySnackbar } from "../events";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

NewsDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  newsDetails: PropTypes.object,
  newsFlag: PropTypes.string.isRequired, // "NEW" oder "EDIT"
};

// ----------------------------------------------------------------------------

export default function NewsDialog({ handleClose, newsDetails, newsFlag }) {
  const [addNewNews] = useAddNewNewsMutation();
  const [updateNews] = useUpdateNewsMutation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const getDefaultValues = () => {
    if (newsFlag === "EDIT") {
      return {
        _id: newsDetails?._id,
        title: newsDetails?.title || "",
        subtitle: newsDetails?.subtitle || "",
        content: newsDetails?.content || "",
      };
    }
    return {
      title: "",
      subtitle: "",
      content: "",
    };
  };

  const { handleSubmit, control, watch, getValues } = useForm({
    defaultValues: getDefaultValues(),
  });

  const onSubmit = async (data) => {
    try {
      if (newsFlag === "NEW") {
        await addNewNews(data).unwrap();
      } else {
        await updateNews({ ...data, _id: newsDetails._id }).unwrap();
      }
      handleClose("REFETCH");
    } catch (error) {
      console.error("Failed to save news:", error);
    }
  };

  return (
    <div>
      <FormProvider {...{ handleSubmit, control, watch }}>
        <BootstrapDialog onClose={() => handleClose()} open>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 2,
            }}
          >
            <Typography
              id="customized-dialog-title"
              sx={{ textAlign: "center", fontSize: "30px", flex: 1 }}
            >
              {newsFlag === "EDIT" ? "Beitrag bearbeiten" : "Beitrag erstellen"}
            </Typography>
            <Box sx={{ flex: "0 0 auto" }}>
              <Iconify
                onClick={handleClose}
                icon="mingcute:close-line"
                style={{ fontSize: "50px", color: "action.active" }}
                sx={{
                  transition: "transform 0.1s",
                  "&:hover": {
                    transform: "scale(1.8)",
                  },
                }}
              />
            </Box>
          </Box>

          <DialogContent dividers>
            <NewsForm control={control} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleClose()}
              variant="contained"
              style={{ background: "#9E9E9E" }}
            >
              Abbrechen
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
            >
              {newsFlag === "EDIT" ? "Aktualisieren" : "Erstellen"}
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </FormProvider>

      <MySnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message="Fehler beim Speichern!"
      />
    </div>
  );
}
