import { Helmet } from "react-helmet-async";
import {
  Grid,
  Button,
  Container,
  Typography,
  Paper,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import TextField from "../components/forms/TextField";
import ControlledSelectField from "../components/forms/ControlledSelectField";
import { useSupportAreaArray } from "../utils/supportUtils";
import { useAddNewSupportMutation } from "../features/support/supportApiSlice";
import { useState } from "react";
import MySlideSnackbar from "../components/snackbar/MySlideSnackbar";

export default function SupportPage() {
  const supportAreaArray = useSupportAreaArray();
  const { handleSubmit, control, watch, reset } = useForm({
    defaultValues: {
      area: "",
      subject: "",
      message: "",
      contactPermission: "no",
      email: "",
      phone: "",
    },
  });

  const [addNewSupport] = useAddNewSupportMutation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [msg, setMsg] = useState("");

  const onSubmit = async (data) => {
    try {
      const supportData = {
        creator: "",
        area: data.area,
        subject: data.subject,
        message: data.message,
        email: data.email,
        phone: data.phone,
      };
      const addSupport = await addNewSupport(supportData);
      if (addSupport) {
        setMsg("Sie haben eine Supportanfrage erfolgreich gesendet!");
        setSnackbarOpen(true);
      }
      reset();
    } catch (error) {
      console.error("Fehler beim Hinzufügen des Supports:", error);
    }
  };

  const contactPermission = watch("contactPermission");

  return (
    <>
      <Helmet>
        <title>Dashboard: Support | Minimal UI</title>
      </Helmet>

      <Container>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "80vh" }}
        >
          <Paper elevation={3} style={{ padding: "24px", textAlign: "center" }}>
            <Typography variant="h4" gutterBottom>
              Support
            </Typography>
            <Typography variant="body1">
              Senden Sie uns eine Nachricht mit Ihrem Anliegen.
              <br /> Wir werden uns schnellstmöglich um Ihr Anliegen kümmern.
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "12px",
                  marginTop: "24px",
                }}
              >
                <ControlledSelectField
                  control={control}
                  name="area"
                  label="Bereich des Anliegens"
                  errorMsg="Bitte wählen Sie ein Bereich aus"
                  fullWidth
                  itemArray={supportAreaArray}
                  required={true}
                />

                <TextField
                  control={control}
                  name="subject"
                  label="Betreff"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required={true}
                />

                <TextField
                  control={control}
                  name="message"
                  label="Ihre Nachricht"
                  multiline
                  variant="outlined"
                  fullWidth
                  minRows={3}
                  margin="normal"
                  required={true}
                />
                <Grid container style={{ alignItems: "center" }}>
                  <Grid item sx={{ mr: "30px" }}>
                    <Typography variant="body1">
                      Dürfen wir uns bei Ihnen melden, falls <br /> wir mehr
                      Fragen zu Ihrem Anliegen haben?
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Controller
                      name="contactPermission"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="Nein"
                            defaultChecked
                          />
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Ja"
                          />
                        </RadioGroup>
                      )}
                    />
                  </Grid>
                </Grid>

                {contactPermission === "yes" && (
                  <>
                    <TextField
                      control={control}
                      name="email"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />

                    <TextField
                      control={control}
                      name="phone"
                      label="Telefonnummer"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  </>
                )}

                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  type="submit"
                  sx={{ marginTop: "24px" }}
                >
                  Absenden
                </Button>
              </Box>
            </form>
          </Paper>
        </Grid>
      </Container>
      <MySlideSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={msg}
      />
    </>
  );
}
