import React from "react";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Iconify from "src/components/iconify";
import NotificationSettings from "src/sections/@dashboard/settings/NotificationSettings";
import TenantSettings from "src/sections/@dashboard/settings/TenantSettings";
import NewPasswordSettings from "src/sections/@dashboard/settings/NewPasswordSettings";
import FAQs from "src/sections/@dashboard/settings/FAQs";
import useAuth from "src/hooks/useAuth";

const SettingsPage = () => {
  const { isAdmin } = useAuth();

  return (
    <Container maxWidth="ml" sx={{ mb: 50 }}>
      <Typography variant="h3" component="h1" gutterBottom>
        Einstellungen
      </Typography>
      {isAdmin && (
        <Accordion>
          <AccordionSummary
            expandIcon={<Iconify icon="ic:round-expand-more" />}
          >
            <Typography variant="h6">Unternehmenseinstellungen</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TenantSettings />
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion>
        <AccordionSummary expandIcon={<Iconify icon="ic:round-expand-more" />}>
          <Typography variant="h6">Benachrichtigungseinstellungen</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <NotificationSettings />
        </AccordionDetails>
      </Accordion>
      {/* <Accordion>
        <AccordionSummary expandIcon={<Iconify icon="ic:round-expand-more" />}>
          <Typography variant="h6">Dashboard einrichten</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Dashboard einrichten</Typography>
        </AccordionDetails>
      </Accordion> */}
      <Accordion>
        <AccordionSummary expandIcon={<Iconify icon="ic:round-expand-more" />}>
          <Typography variant="h6">Passwort ändern</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <NewPasswordSettings />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<Iconify icon="ic:round-expand-more" />}>
          <Typography variant="h6">FAQs</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FAQs />
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default SettingsPage;
